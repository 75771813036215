import React from "react";
import "@css/news/searchfour.css";
import contact from "@images/contact.png";
import vs from "@images/2viewstation_logo.svg";
import analy from '../assets/images/analy/analy.svg';
import euci from "@images/euci.svg";
import { useState, useEffect } from "react";
import { getContact, getAnaly, getVs, getEuci, getAllnews } from "../utils";
import { Link } from "react-router-dom";
import loading from "@images/loading.png";


// const winUrl = window.location.href;
// const winUrlSplit = winUrl.split('/')[3];
// console.log(winUrlSplit);
const Searchfour = () => {
  const [msg, setMsg] = useState("");
  const [current, setCurrent] = useState("");
  const [pageIndex, setPageIndex] = useState(1);
  const [contactText, setContact] = useState([]);
  const [analyText, setAnalyText] = useState([]);
  const [vsText, setVs] = useState([]);
  const [euciText, setEuci] = useState([]);
  const [allnews, setAllnews] = useState([]);
  const [load, setLoad] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');

  useEffect(() => {
    const winUrl = window.location.pathname;
    const winUrlSplita = winUrl.split('/')[1];
    setWinUrlSplit(winUrlSplita)
  }, [window.location])

  const [showLogo, setShowLogo] = useState([
    { img: contact, check: 0, id: 0 },
    { img: analy, check: 0, id: 1 },
    { img: vs, check: 0, id: 2 },
    { img: euci, check: 0, id: 3 },
  ]);
  const showLogos = [
    { img: contact, check: 0, id: 0 },
    { img: analy, check: 0, id: 1 },
    { img: vs, check: 0, id: 2 },
    { img: euci, check: 0, id: 3 },
  ];
  const onClickshow = (currentItem) => {
    const { id, check } = currentItem;
    const news = showLogos;
    news.forEach((item) => {
      if (item.id === id) {
        item.check = !check;
        setShowLogo(news);
        if (item.check === false) {
          setCurrent("");
        } else if (item.check === true) {
          setCurrent(id);
        }
      }
    });
    setPageIndex(1);
  };
  const checkNews = () => {
    if (current === 0) {
      return contactText;
    } else if (current === 1) {
      return analyText;
    } else if (current === 2) {
      return vsText;
    } else if (current === 3) {
      return euciText;
    } else if (current === "") {
      return allnews;
    }
  };

  // **********************
  const onclickMore = (i) => {
    setPageIndex(i);
    // console.log(pageIndex);
  };

  const onclickBread = (i) => {
    localStorage.setItem("route", "news");
  };
  const getList = () => {
    setLoad(false);
    if (current === 0) {
      getContact(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setContact(res.Data);
            } else {
              setContact(() => {
                contactText.push(...res.Data);
                return [...contactText];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === 1) {
      getAnaly(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setAnalyText(res.Data);
            } else {
              setAnalyText(() => {
                analyText.push(...res.Data);
                return [...analyText];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === 2) {
      getVs(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setVs(res.Data);
            } else {
              setVs(() => {
                vsText.push(...res.Data);
                return [...vsText];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === 3) {
      getEuci(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setEuci(res.Data);
            } else {
              setEuci(() => {
                euciText.push(...res.Data);
                return [...euciText];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === "") {
      getAllnews(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setAllnews(res.Data);
            } else {
              setAllnews(() => {
                allnews.push(...res.Data);
                return [...allnews];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    }
  };
  useEffect(() => {
    getList();
  }, [current, pageIndex, showLogo, msg]);

  return (
    <div className="sear_big flex">
      <div className="sear_check flex">
        <div className="check"></div>
        <div className="sear_check_center">
          {showLogo.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => onClickshow(item)}
                className={item.check ? "search_background" : null}
              >
                <img src={item.img} alt="" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="sear_four">
        {load ? (
          <div className="sear_four_text">
            {checkNews().map((item, i) => {
              return (
                <div key={i} 
                // onClick={() => onclickBread(item.NewsTypeName)}
                >
                  <Link to={`/detail/${item.Id}?type=${winUrlSplit}`} target="_blank" className="link-container">
                    <img src={item.FullThumbImage} alt="" />
                    <div className="sear_four_text_box">
                      <h1>{item.NewsTitle}</h1>
                      <p>{item.NewsDesc}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="sear_loading">
            <img src={loading} alt="" />
            <p>加载中...</p>
          </div>
        )}

        <button onClick={() => onclickMore(pageIndex + 1)}>
          {load ? "加载更多" : "加载中..."}
        </button>
      </div>
    </div>
  );
};

export default Searchfour;
