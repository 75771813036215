import React, { useEffect, useState } from "react";
import { getProductAnaly } from "../utils";
import { Link } from "react-router-dom";
import loading from "@images/loading.png";
import "@css/ThreeText.css";

const AnalyNews = (props) => {
  const { home } = props;
  const [analyNews, setAnalyNews] = useState([]);
  const [load, setLoad] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');

  const onclickRoute = () => {
    if (home === 0) {
      localStorage.setItem("route", "home");
    } else {
      return localStorage.setItem("route", "product");
    }
  };
  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit.slice(0, -6))
  }, [window.location])

  useEffect(() => {
    setLoad(false);
    getProductAnaly()
      .then((res) => {
        if (!res.Message) {
          setAnalyNews(res.Data);
        }
      })
      .finally(() => {
        setLoad(true);
      });
  }, []);
  return (
    <div className="news" onClick={() => onclickRoute()}>
      {load ? (
        <div className="news_text">
          {analyNews.map((item, i) => {
            return (
              <div key={i}>
                <Link to={`/detail/${item.Id}?type=${winUrlSplit}`} target="_blank">
                  <img src={item.FullThumbImage} alt="" />
                  <div className="news_text_box">
                    <h1>{item.NewsTitle}</h1>
                    {
                      document.body.clientWidth < 900&&(
                        <p>{item.NewsDesc}</p>
                      )
                    }
                    {
                      document.body.clientWidth > 1000&&(
                        <p>{item.NewsDesc}</p>
                      )
                    }
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="threeTextLoading">
          <img src={loading} alt="" />
          <p>加载中...</p>
        </div>
      )}
    </div>
  );
};

export default AnalyNews;
