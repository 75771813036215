import React, { useState, useCallback, useEffect, useRef } from "react";
import { LongText } from "../contanst/index";
import { LongLogo } from "../contanst/index";
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import "@css/text.css";

const Text = () => {
  const [current, setCurrent] = useState(0);

  const [width, setWidth] = useState("");

  const pathName = window.location.href;
  const [path, setPath] = useState({});
  const [scrollPosition, setScrollPosition] = useState(0);
  const sliderRef = useRef(null);
  const sliderContainerRef = useRef(null);
  
  // // 每三秒切换一个
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrent((prevIndex) => (prevIndex + 1) % LongLogo.length);
  //   }, 4000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);
  // // 切换最后一个时，滚动条滑到最后
  // useEffect(() => {
  //   if (current === LongLogo.length - 1) {
  //     srcollRef.current.scrollLeft = srcollRef.current.scrollWidth;
  //   } else if (current === 0) {
  //     srcollRef.current.scrollLeft = 0;
  //   }
  // }, [current]);

  const onClickPic = (key) => {
    setCurrent(key);
  };
  const buttonPaths = {
    '关于CIM Database的更多信息': 'contact',
    '关于ANALY TICSCREATOR的更多信息': 'analy',
    '关于3DViewstation的更多信息': '3dvs',
    '关于POWERWORKER的更多信息': 'powerwork'
  };
  
  const onclickJump = (item) => {
    console.log(item);
    if (current < 3) {
      localStorage.setItem("jump", current);
    }
    const buttonText = item.but;
    const pathKey = buttonPaths[buttonText] || 'contact';
    setPath(pathName.replace('home', `product?${pathKey}`));
  };

  const show = useCallback((cur) => {
    return current === cur ? "active" : "product_content";
  }, [current]);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setWidth(window.innerWidth);
    });
  }, [width]);

  const handleScrollLeft = () => {
    sliderContainerRef.current.scrollLeft -= sliderRef.current.offsetWidth;
  };

  const handleScrollRight = () => {
    sliderContainerRef.current.scrollLeft += sliderRef.current.offsetWidth;
  };
  return (
    <div className="long_big flex">
      <div className="long">
        {document.body.clientWidth > 1000 && (
         <div className='long-icon left'>
            <LeftOutlined
              style={{ fontSize: '0.3rem', margin: '0px 24px 0 -15px' }}
              onClick={handleScrollLeft}
            />
          </div> 
        )}
        <div className="long-slide" >
          <div className="slide-contain" ref={sliderContainerRef}>
            <div
              className="long_text"
              ref={sliderRef}
            >
              {LongLogo.map((item, i) => {
                return (
                  <div onClick={() => onClickPic(i)} key={i}>
                    <div className={i === current ? "long_background" : undefined}>
                      <div className="long_img">
                        <img
                          className={i === current ? "long_img_logoactive" : 'long_img_logo'}
                          src={item.img}
                          alt=""
                        />
                        {i === current && !item.before ? (
                          <div className="three"></div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {document.body.clientWidth > 1000 && (
          <div className='long-icon right'>
            <RightOutlined
              style={{ fontSize: '0.3rem', margin: '0 -15px 0 20px' }}
              onClick={handleScrollRight}
            />
          </div> 
        )}
      </div>
      {width > 1000 && (
        <div className="product">
          {LongText.map((item, index) => {
            return (
              <div key={index}>
                <div className={show(index)}>
                  <div className="product">
                    <div className="product_left">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="product_right">
                      <h1 className="right_title">{item.title}</h1>
                      <p className="product_text">{item.text}</p>
                      <ul>
                        {item.list.map((items, i) => {
                          return (
                            <li key={i}>
                              <i className="iconfont icon-triangle-right"></i>
                              {items.l1}
                            </li>
                          );
                        })}
                      </ul>
                      <a href={path} rel="noopener noreferrer">
                        <button
                          className={
                            current > 3 ? "product_buttton_none" : null
                          }
                          onClick={() => onclickJump(item)}
                        >
                          {item.but}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {width < 1000 && (
        <div className="product">
          {LongText.map((item, index) => {
            return (
              <div key={index}>
                <div className={show(index)}>
                  <div className="product">
                    <div className="product_left">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="product_right">
                      <h1 className="right_title">{item.title}</h1>
                      <p className="product_text">{item.text}</p>
                      <ul>
                        {item.list.map((items, i) => {
                          return (
                            <li key={i}>
                              <i className="iconfont icon-triangle-right"></i>
                              {items.l1}
                            </li>
                          );
                        })}
                      </ul>
                      <a href={path} rel="noopener noreferrer">
                        <button
                          className={
                            current > 3 ? "product_buttton_none" : null
                          }
                          onClick={() => onclickJump(item)}
                        >
                          {item.but}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Text;
