import React from "react";
import "@css/product/approval.css";
import { Authentication, Report, Digital, FamousIndustry } from "../contanst";

const ProductApproval = () => {
  return (
    <div>
      <div className="flex">
        <div className="approval_title">
          <h1>广泛的业界认可和认证</h1>
          <p>CONTACT系统被相关专业咨询机构持续推进</p>
        </div>
        <div className="approval_big">
          <div className="flex">
            <div className="approval_big_center">
              {Report.map((item, i) => {
                return (
                  <div className="approval_text" key={i}>
                    <div className="appreval_report">
                      <h1>{item.title}</h1>
                      <p>{item.text}</p>
                    </div>
                    <div>
                      <img src={item.pic} className="appreval_logo" alt="" />
                    </div>
                  </div>
                );
              })}
              <div className="approval_world">
                {Authentication.map((item, i) => {
                  return (
                    <div key={i}>
                      <h1>{item.title}</h1>
                      <p>{item.text}</p>
                    </div>
                  );
                })}
              </div>
              <div className="xian flex"></div>
              {Digital.map((item, i) => {
                return (
                  <div className="approval_bottom" key={i}>
                    <img src={item.pic} alt="" />
                    <div className="approval_bottom_text">
                      <p className="Germany">{item.title1}</p>
                      <p className="Germany_title">{item.title2}</p>
                      <p className="Germany_text">{item.title3}</p>
                    </div>
                  </div>
                );
              })}
              {FamousIndustry.map((item, i) => {
                return (
                  <div key={i} className="approval_bottom1">
                    <div className="approval_bottom_text1">
                      <p className="approval_famous">{item.title}</p>
                      <p className="approval_year">{item.text}</p>
                    </div>
                    <img src={item.pic} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductApproval;
