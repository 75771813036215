import React from "react";
import "@css/news/searinput.css";
import contact from "@images/contact.png";
import vs from "@images/2viewstation_logo.svg";
import analyTics from '../assets/images/analy/analy.svg';
import euci from "@images/euci.png";
import { useState, useEffect } from "react";
import { getCIM, getVS, getAnalyTics, getZhizong, getTechAll } from "../utils";
import { Link } from "react-router-dom";
import loading from "@images/loading.png";


const Searchinput = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [CIM, setCIM] = useState([]);
  const [analys, setAnaly] = useState([]);
  const [VS, setVS] = useState([]);
  const [zhizong, setZhizong] = useState([]);
  const [allnews, setAllnews] = useState([]);
  const [load, setLoad] = useState("");
  const [current, setCurrent] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');
  const [showLogo, setShowLogo] = useState([
    { img: contact, check: 0, id: 0 },
    { img: analyTics, check: 0, id: 1 },
    { img: vs, check: 0, id: 2 },
    { img: euci, check: 0, id: 3 },
  ]);
  const showLogos = [
    { img: contact, check: 0, id: 0 },
    { img: analyTics, check: 0, id: 1 },
    { img: vs, check: 0, id: 2 },
    { img: euci, check: 0, id: 3 },
  ];


  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])


  const checkNews = () => {
    if (current === 0) {
      return CIM;
    } else if (current === 1) {
      return analys;
    } else if (current === 2) {
      return VS;
    } else if (current === 3) {
      return zhizong;
    } else if (current === "") {
      return allnews;
    }
  };

  const onClickshow = (currentItem) => {
    const { id, check } = currentItem;
    const news = showLogos;
    news.forEach((item) => {
      if (item.id === id) {
        item.check = !check;
        setShowLogo(news);
        if (item.check === false) {
          setCurrent("");
        } else if (item.check === true) {
          setCurrent(id);
        }
      }
    });
    setPageIndex(1);
  };
  const onclickMore = (i) => {
    setPageIndex(i);
  };
  // const onclickBread = (i) => {
  //   localStorage.setItem("route", "technology");

  // };
  const getList = () => {
    setLoad(false);
    if (current === 0) {
      getCIM(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setCIM(res.Data);
            } else {
              setCIM(() => {
                CIM.push(...res.Data);
                return [...CIM];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === 1) {
      getAnalyTics(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setAnaly(res.Data);
            } else {
              setAnaly(() => {
                analys.push(...res.Data);
                return [...analys];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === 2) {
      getVS(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setVS(res.Data);
            } else {
              setVS(() => {
                VS.push(...res.Data);
                return [...VS];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === 3) {
      getZhizong(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setZhizong(res.Data);
            } else {
              setZhizong(() => {
                zhizong.push(...res.Data);
                return [...zhizong];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    } else if (current === "") {
      getTechAll(pageIndex)
        .then((res) => {
          if (!res.Message) {
            if (pageIndex === 1) {
              setAllnews(res.Data);
            } else {
              setAllnews(() => {
                allnews.push(...res.Data);
                return [...allnews];
              });
            }
          }
        })
        .finally(() => {
          setLoad(true);
        });
    }
  };
  useEffect(() => {
    getList();
  }, [current, pageIndex, showLogo]);
  return (
    <div className="input_big flex">
      <div className="input_check flex">
        <div className="input_check_center">
          {showLogo.map((item, i) => {
            return (
              <div
                key={i}
                onClick={() => onClickshow(item)}
                className={item.check ? "search_background" : null}
              >
                <img src={item.img} alt="" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="input_four">
        <div className="input_four_text">
          {load ? (
            <div className="input_four_box">
              {checkNews().map((item, i) => {
                console.log(item);
                return (
                  <div
                    key={i}
                    className="tech_box"
                    // onClick={() => onclickBread(item.NewsTypeName)}
                  >
                    <Link to={`/detail/${item.Id}?type=${winUrlSplit}`} target="_blank" className="link-container">
                      <img src={item.FullThumbImage} alt="" />
                      <div className="tech_text">
                        <h1>{item.NewsTitle}</h1>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="sear_loading">
              <img src={loading} alt="" />
              <p>加载中...</p>
            </div>
          )}
        </div>
        <button
          onClick={() => onclickMore(pageIndex + 1)}
          className="input_four_button"
        >
          {load ? "加载更多" : "加载中..."}
        </button>
      </div>
    </div>
  );
};

export default Searchinput;
