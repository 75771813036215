import React, { useState, useEffect } from 'react';
import '../assets/css/product/power-work.css';
import Slider from "react-slick";
import loading from "@images/loading.png";
import PowerWorkNews from '../components/power-work-news';
import powerLogo from "@images/3powerworker_blue_logo.svg";

import bulbs from '../assets/images/power/power-bulbs.png';
import weldAcross from '../assets/images/power/welding-across.jpg';
import weldVertical from '../assets/images/power/welding-vertical.png';
import lpa from '../assets/images/power/LPA.jpg';

import { getProPowerWork } from "../utils";

const PowerWork = () => {
  const [powerList, setPowerList] = useState([]);
  const [powerLoad, setPowerLoad] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');
  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])

  const settings = {
    dots: true,
    arrows: true, 
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setPowerLoad(false);
    getProPowerWork()
      .then((res) => {
        setPowerList(res.Data);
      })
      .finally(() => {
        setPowerLoad(true);
      });
  }, []);
  return(
    <div className='power-work-div'>
      {/* banner */}
      <div className='flex'>
        <div className="pro">
          {powerLoad ? (
            <Slider {...settings}>
              {powerList.map((item, i) => {
                const urlPro = item.LinkNewsFullUrl;
                const urles = `?type=${winUrlSplit.slice(0, -10)}`;
                return (
                  <div key={i}>
                    <a href={urlPro+urles} target={"_blank"} rel="noreferrer">
                      <div className="power_text">
                        <div className="power_text_box">
                          <p className="power_text_desc">
                            <span className="power_text_title">
                              {item.AdvTitle}
                            </span>
                            {item.AdvDesc}
                          </p>
                        </div>
                      </div>
                    </a>
                    <img src={item.AdvImageFullUrl} className="power_big" alt="" />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="pro_car_loading">
              <img src={loading} alt="" />
              <p>加载中...</p>
            </div>
          )}
        </div>
      </div>
      {/* 官网直达 */}
      <div className="flex">
        <div className="power-logo-text">
          <img src={powerLogo} className="pro-logo-powerimg" alt="" />
          <div className="pro-logo-power">面向制造业精益创新移动应用集合</div>
        </div>
      </div>
      {/* 详情介绍 */}
      <div className="power-detail-text">
        <div className="power-detail-center">
          <div className="power_center">
            <div className="power_logo">
              <img className="power-left-img" src={weldAcross} alt="" />
              <div className="power-right">
                <div className="power-right-text">
                  <h1>POWERWORKER面向制造业精益创新移动应用</h1>
                  <p >
                    很多应用都追求技术，我们将目光重新聚焦回员工，关注员工体验，用
                    最简单的逻辑实现企业众多的小场景应用，小应用大收益，让普通员工
                    融入企业的管理改善，业务改善，精益增效的方方面面。
                  </p>
                  <p style={{ color: '#042e4e' }}>
                    “联结员工小应用，赋能制造大创新！”
                  </p>
                </div>
                <div className='power-right-icon'>
                  <div className='icon'>移动端</div>
                  <div className='icon'>网页端</div>
                  <div className='icon'>专注小应用场景</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="power-long-content">
        <div className="power-first-content">
          <div className="power-content-top">
           <h1>解决方案</h1>
            <p>
              我们与客户一起研究企业内部的精益改善场景，打造以员工应用视角全员参与改善的一系列灵活应用。
              “联结员工小应用，赋能制造大创新”
            </p> 
          </div>
          <div className="power-Details">
            <div>
              <h1>合理化建议</h1>
              <div className="power-dividing-line"></div>
              <h1>场景</h1>
              <div>
                合理化建议制度又称为<span>奖励建议制度</span>、改善提案制度、创造性思考制度，是一种规范化的企业内
                部沟通制度，旨在鼓励广大员工能够直接参与企业管理，下情上达，让员工能够与企业的管理者
                保持经常性的沟通
              </div>
              <h1>功能</h1>
              <div>
                移动端实现标准的合理化建议6个流程步骤：创建-评估-分配/认领-实施-验收-评分。系统提
                供图片视频上传，改善位置细分到每个产线或设备。
              </div>
              <div>
                整个流程自驱动，并在流程结束后自动获得积分奖励，奖励积分可用于线下直接支付兑换商
                品。
              </div>
            </div>
            <img src={bulbs} style={{ marginLeft: '20px' }} className="power-details-img" alt="" />
          </div>
        </div>
        <div className="power-second-content">
          <div className="power-Details">
            <img src={weldVertical} style={{ marginRight: '20px' }} className="power-details-img" alt="" />
            <div>
              <h1>安全行为观察</h1>
              <div className="power-dividing-line"></div>
              <h1>场景</h1>
              <div>
                不安全行为是导致事故发生的最为重要的原因。任何一件事情，如果客观存在着发生某种事故的
                可能性，不管这个可能性有多小，如果重复去做时，事故总会在某一时刻发生。
              </div>
              <div>
                <span>行为安全管理</span>
                是以心理学与行为学为理论基础，通过观察和改变人的行为而达到安全的目标，进
                而实现企业事故预防，减少由于人的行为引起的事故发生。
              </div>
              <h1>功能</h1>
              <div>
                员工可以快速高效的实现移动端的不安全行为的上报，按照标准的流程直至不安全行为的消除。
              </div>
            </div>
          </div>
        </div>
        <div className="power-second-content">
          <div className="power-Details">
            <div>
              <h1>分层审核LPA</h1>
              <div className="power-dividing-line"></div>
              <h1>场景</h1>
              <div>
                <span>分层审核（LPA，Layered Process Audits）</span>
                可验证流程，控制计划，管理程序有效性并有效监
                管执行等，其中过程分层审核是福特汽车，通用汽车，克莱斯特汽车等极为推崇的一套有效的现
                场管理模式
              </div>
              <h1>功能</h1>
              <div>
                提供不同的分层审核任务模板，并定义分层审核角色和人员，系统设定分层审核的计划，由
                系统自动触发到对应人员的每日审核任务。
              </div>
              <div>
                在移动端用户可以轻松的检查自己的审核任务并完成审核任务的确认。同时对产生的问题进
                行闭环管理。
              </div>
            </div>
            <img src={lpa} className="details-img-lpa" alt="" />
          </div>
        </div>
      </div>
      {/* 最新消息 */}
      <div className="flex" style={{ marginTop: '50px' }}>
        <div className="pro_new_tittle">
          <h1>最新消息</h1>
        </div>
        <PowerWorkNews />
      </div>
    </div>
  )
};

export default PowerWork;