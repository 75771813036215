import React from "react";
import Header from "../components/Header";
import "@css/relations.css";
import g601 from "@images/1,g60.png";
import g602 from "@images/4,g60.jfif";
import g603 from "@images/5,g60.jfif";
import g604 from "@images/6,g60.jfif";
import Footer from "../components/Footer";

const Relation = () => {
  return (
    <div>
      <Header />
      <div className="banner_box">
        <div className="black_overlay">
          <div className="banner_container">
            <h1>CONTACT US</h1>
            <h2>查看公司的官方联系方式和地址。</h2>
          </div>
        </div>
      </div>
      <h3 className="information_title">我们的信息</h3>
      <div className="information">
        <div>
          <div className="information_left">
            <div className="icon_item">
              <i className="iconfont icon-daohangdizhi"></i>
            </div>
            <div className="icon_item_text">
              <div className="icon_item_title">上海</div>
              <div className="icon_item_description">
                松江区千帆路288弄G60科创云廊8号楼1004室
              </div>
            </div>
          </div>
          <div className="information_left">
            <div className="icon_item">
              <i className="iconfont icon-dianhua"></i>
            </div>
            <div className="icon_item_text">
              <div className="icon_item_title">电话号码</div>
              <div className="icon_item_description">021 37708778</div>
            </div>
          </div>
          <div className="information_left">
            <div className="icon_item">
              <i className="iconfont icon-a-youjianchakanyoujianfasongyoujianshouyoujian-06"></i>
            </div>
            <div className="icon_item_text">
              <div className="icon_item_title">电子邮件</div>
              <div className="icon_item_description">
                info@euci-software.com
              </div>
            </div>
          </div>
          <div className="information_left">
            <div className="icon_item">
              <i className="iconfont icon-daohangdizhi"></i>
            </div>
            <div className="icon_item_text">
              <div className="icon_item_title">德国. CONTACT Software</div>
              <div className="icon_item_description">
                Office +49 421 20153-0
              </div>
              <div className="icon_item_description2">Wiener Straße 1–3, 28359 Bremen, Germany</div>
            </div>
          </div>
        </div>
        <div>
          <img src={g601} alt="" className="g601" />
          <img src={g602} alt="" className="g601" />
          <img src={g603} alt="" className="g601"/>
          <img src={g604} alt="" className="g601"/>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Relation;
