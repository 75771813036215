import React from "react";
import { useState, useEffect } from "react";
import dataSpecialist from '../assets/images/analy/data-specialist.png';
import Analy from '../assets/images/analy/analy.svg';

import arrowDown from '../assets/images/analy/arrow-circle-down.svg';
import people from '../assets/images/analy/people.jpg';
import dollar from '../assets/images/analy/Dollar-icon.jpg';
import playCircle from '../assets/images/analy/play-circle.svg';
import activity from '../assets/images/analy/activity.svg';
import star from '../assets/images/analy/star.svg';

import construction from '../assets/images/analy/purple1.svg';
import modernization from '../assets/images/analy/purple2.svg';
import power from '../assets/images/analy/power.svg';
import vault from '../assets/images/analy/vault.svg';
import cloudData from '../assets/images/analy/cloud-data.svg';
import microsoft from '../assets/images/analy/microsoft.svg';
import lakehouse from '../assets/images/analy/lakehouse.svg';
import exportes from '../assets/images/analy/export.svg';
import push from '../assets/images/analy/push.svg';
import synapse from '../assets/images/analy/synapse.svg';


import AnalyNews from "../components/AnalyNews";
import Slider from "react-slick";
import loading from "@images/loading.png";

import { getProAnaly } from "../utils";
import "../assets/css/product/analy-creator.css";

const AnalyCreator = () => {
  const [analyList, setAnalyList] = useState([]);
  const [analyLoad, setAnalyLoad] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])

  useEffect(() => {
    setAnalyLoad(false);
    getProAnaly()
      .then((res) => {
        setAnalyList(res.Data);
      })
      .finally(() => {
        setAnalyLoad(true);
      });
  }, []);
  return (
    <div className='analy-creator-div'>
      <div className="flex">
        <div className="pro">
          {analyLoad ? (
            <Slider {...settings}>
              {analyList.map((item, i) => {
                const urlPro = item.LinkNewsFullUrl;
                const urles = `?type=${winUrlSplit.slice(0, -6)}`;
                console.log(urles);
                return (
                  <div key={i}>
                    <a href={urlPro+urles} target={"_blank"} rel="noreferrer">
                      <div className="analycar_text">
                        <div className="analycar_text_box">
                          <p className="analycar_text_desc">
                            <span className="analycar_text_title">
                              {item.AdvTitle}
                            </span>
                            {item.AdvDesc}
                          </p>
                        </div>
                      </div>
                    </a>
                    <img src={item.AdvImageFullUrl} className="analy_big" alt="" />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="pro_car_loading">
              <img src={loading} alt="" />
              <p>加载中...</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="analy_text">
          <img src={Analy} className="pro_analyimg" alt="" />
          <div className="pro-analy">熠格是中国唯一产品销售和技术合作伙伴</div>
          <p className="analy-href">
            <a style={{ marginRight: '10px', color: '#000000' }} href="https://www.analyticscreator.com/" target="_blank" rel="noreferrer">
              官网直达
            </a>
            <a style={{ color: '#000000' }} href="https://www.analyticscreator.com/partners" target="_blank" rel="noreferrer">
              查看合作资源
            </a>
          </p>
        </div>
      </div>
      <div className="analy-long-text">
        <div className="analy-long-center">
          <div className="analy_center">
            <div className="analy_logo">
              <img className="analy-left-img" src={dataSpecialist} alt="" />
              <div className="analy-right">
                <div className="analy-right-text">
                  <h1>数据仓库专家 SINCE 2024Y</h1>
                  <p className="analy-text">
                    有了 analyticscreator，我们就能把更多时间花在提出有价值的见解上，
                    而不是管理数据管道上
                  </p>
                  <p >
                    经过验证、值得信赖的技术自 2004 年以来，已在数百个项目中使用。一个人
                    就能完成 5 位专家同时完成的工作。
                  </p>
                  <p>
                    WITH ANALYTICSCREATOR WE SPEND MORE
                    TIME SURFACING VALUABLE INSIGHTS AND LESS
                    TIME MANAGING THE DATA PIPELINE
                    Proven and trusted technology used in many
                    hundreds of projects since 2004. One person you
                    can do what 5 Experts can do in the same time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="analy-background">
          <div className="icon-group">
            <div className="icon-background">
              <img src={dollar} style={{ width: "26px", height: "26px" }} alt="" />
            </div>
            <div>
              <p>节省时间和金钱</p>
              <p className="group-text">只需几小时，而不是几天就能取得成果。</p>
            </div>
          </div>
          <div className="icon-group">
            <div className="icon-background">
              <img src={people} style={{ width: "26px", height: "26px" }} alt="" />
            </div>
            <div>
              <p>容易</p>
              <p className="group-text">它既适合专家，也适合非专家。</p>
              <p className="group-text">专家在几天内就能完成一个项目。</p>
            </div>
          </div>
          <div className="icon-group">
            <div className="icon-background">
              <img src={arrowDown} style={{ width: "26px", height: "26px" }} alt="" />
            </div>
            <div>
              <p>降低风险</p>
              <p className="group-text">结束永无止境的项目和不断变化的需求的噩梦。</p>
              <p className="group-text">您将预先明确项目的时间范围。</p>
            </div>
          </div>
          <div className="icon-group">
            <div className="icon-background">
              <img src={star} style={{ width: "26px", height: "26px" }} alt="" />
            </div>
            <div>
              <p>现代标准</p>
              <p className="group-text">可使用最先进的方法和架构，如数据仓库、云解决方案、实时等。</p>
            </div>
          </div>
          <div className="icon-group">
            <div className="icon-background">
              <img src={activity} style={{ width: "26px", height: "26px" }} alt="" />
            </div>
            <div>
              <p>敏捷工作</p>
              <p className="group-text">随时随地更改整体数据模型，立即生成代码。</p>
            </div>
          </div>
          <div className="icon-group">
            <div className="icon-background">
              <img src={playCircle} style={{ width: "26px", height: "26px" }} alt="" />
            </div>
            <div>
              <p>合规与治理</p>
              <p className="group-text">自动化文档和强大的数据线为所有利益相关者带来透明度。</p>
              <p className="group-text">自上而下和自下而上的建模。</p>
            </div>
          </div>
        </div> 
      </div>
      <div className="analy-long-content">
        <div className="first-content">
          <div className="content-top">
           <h1>解决方案</h1>
            <p>
              快速创建数据仓库。连接任何数据源 将数据传输到立方体或 MS 表格式模型。为任何商业智能前端快速创建分析模型。
            </p> 
          </div>
          <div className="Details">
            <div>
              <h1>构建新的数据分析平台</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                公司希望在新建站点上重组数据的原因有很多：要么是公司还没有数据仓库，
                要么是建立一个新的数据仓库比改造现有的数据仓库更有效率。即使是现有解决方案的扩展，
                基本上也是有限的新实施。建立数据仓库需要时间、资金和技术诀窍。为此，通常使用 ELT 或 ETL 工具。
                这意味着手动开发过程，即缓慢和按部就班的开发过程，已无法满足当今快速变化的业务需求。
              </p>
              <h1>方案</h1>
              <p>
                使用AnalyticsCreator，您可以立即创建数据分析应用程序，例如具有图形支持的数据仓库，
                连接任何数据源，将数据传输到多维数据集或MS表格模型，并使分析模型可用于任何BI /分析前端。
                通过这种方式，您可以轻松地将业务需求与数据源联系起来，并直接在AnalyticsCreator中映射核心业务逻辑。
                如果数据源、逻辑或流程中发生更改，则更改会自动更新并在所有级别中发布。
              </p>
            </div>
            <img src={construction} className="details-img" alt="" />
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <img src={modernization} className="details-img" alt="" />
            <div>
              <h1>分析平台现代化</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                当前数据仓库已经过时，不再满足数据基础应提供给分析前端的新扩展要求。
                它的性能可能不再足够，适应难以实施，并且不再面向未来。此外，由于顾问的员工或顾问不再可用，
                有关现有数据仓库的专业知识可能不再可用。
              </p>
              <h1>方案</h1>
              <p>
                AnalyticsCreator允许您在现有的DWH上进行扩展和调整。如果有良好的基础，就很
                容易建立在它之上。此外，AnalyticsCreator的逆向工程方法使您能够从现有的DWH应用
                程序中获取代码并将其集成到AC中。这样，更多的层/区域可以包含在自动化中，从而更广泛
                地支持预期的变更过程。手动开发的DWH（即使用ETL/ELT工具）的扩展可能会快速消耗时间和资源。
                根据我们的经验和可以在网络上找到的各种研究，可以得出以下规则：生命周期越长，成本就越高。
              </p>
            </div>
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <div>
              <h1>适用于 POWER BI 的数据平台</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                挑战在于使用 Power BI 连接不同的数据源。这涉及处理大量数据，
                这可能很复杂。此外，需要对数据进行历史化，以使用相应的高级分析
                工具和方法。需要构建表格模型等等。Power BI 是全球最受欢迎的分析前端之一。
                在 Power BI 中，可以为分析应用程序设计多维模型，并将其连接到云中或现场的数据源。
                为了实现更高的数据质量和意义，通常从源中提取数据，进行清理，逻辑准备，
                连接到相关数据并进行聚合。此外，基于模型和技术性能优化也是必要的。另一个非常相关的点是，
                源数据的数据历史记录化应实现并存储在数据仓库中，这在 Power BI 中是不可能的。只有这样，
                才能及时和正确地进行分析和报告。
              </p>
              <h1>方案</h1>
              <p>
                使用 AnalyticsCreator，可以为分析 Power BI 应用程序设计数据模型，
                并使用适当的加载策略自动生成多层数据仓库。在此过程中，业务逻辑映射到
                AnalyticsCreator中的一个位置，并在各种Microsoft技术中实现。该模型可以部署
                在Azure SQL DB，Azure Analysis Services，Power BI甚至本地技术中。
                AnalyticsCreator还使您能够连接到大量数据源并将其交付给其他前端技术。
                AnalyticsCreator允许您连接到所需的所有数据源，并创建一个额外的时间线，允许您从数据仓库访问任何时间片。
              </p>
            </div>
            <img src={power} className="details-img" alt="" />
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <img src={vault} className="details-img" alt="" />
            <div>
              <h1>数据保险箱 2.0 自动化</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                如果您有许多源系统、大量数据或可能经常更改的复杂数据模型，那么 Data Vault 2.0 
                建模非常适合构建数据分析平台。或者，您可能通常已决定使用 Data Vault 2.0 建模方法
                构建数据分析平台。无论哪种方式，都建议使用自动化工具处理此类复杂的项目。需要提供历史化概念、
                自动哈希密钥生成以及从数据保管库模型到前端（例如 Power BI）的维度模型的转换。
              </p>
              <h1>方案</h1>
              <p>
                AnalyticsCreator 包含一个向导，该向导可在连接源数据后立即生成数据保管库模型的初稿。
                这样，开发人员就可以对其进行自定义并保存自己的模板以供将来的草稿使用。AnalyticsCreator 
                会通过提示提醒开发人员，如果他们超出了 DV 2.0 的严格规则，但不限制开发人员。此外，
                AnalyticsCreator还提供了从DV模型过渡到维度模型的可能性。后者可以直接导致多维数据集或表格技术，
                也可以转移到BI/分析前端。我们将此过程称为混合方法。此外，可以在经典的多维模型中使用DV 2.0成就，
                从而结合两个世界的优势。
              </p>
            </div>
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <div>
              <h1>切换到云数据分析平台 AZURE</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                关于如何在云中操作数据仓库，有许多体系结构和想法。但是哪个概念是正确的呢？
                越来越多的ERP和软件提供商在云中提供运营解决方案。因此，连接这些数据源并将其传输到云数据仓库是有意义的。
              </p>
              <h1>方案</h1>
              <p>
                使用AnalyticsCreator，您可以主要独立建模，无论您的数据应存储在云中还是本地。
                首先，您需要考虑商业模式。在部署过程中的最晚时间，确定模型是在本地、云中还是在 
                Azure 上生成或迁移。对于特殊的混合架构，有必要指定要存储和加载哪些区域/层。
              </p>
            </div>
            <img src={cloudData} className="details-img" alt="" />
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <img src={microsoft} className="details-img" alt="" />
            <div>
              <h1>从 MICROSOFT BI 堆栈中的 SAP 源创建数据分析平台</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                您的源系统是 SAP。您可能还希望将其他数据源（如社交媒体数据、云数据、IOT 数据等）
                提供给用户，以便在云中或本地的中央数据分析平台中进行分析。为此，您通常需要专业知识、大量时间和正确的工具。
              </p>
              <h1>方案</h1>
              <p>
                AnalyticsCreator包括特殊的组件，允许您访问SAP源并从中解码元数据，并创建AnalyticsCreator元数据连接器。
                此外，预定义的SAP元数据连接器已在AnalyticsCreator中可用。由此，AC-Wizard会自动创建一个多层数据仓库
                （DV 2.0或Dimensional），可以自定义。Microsoft表格、多维数据集模型、Power BI 应用程序、Tableau、
                QlikSense 模型都是从中自动生成的。对于运行操作，我们建议使用 Theobald 公司数据连接器，该连接器根据要求在包中提供。
              </p>
            </div>
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <div>
              <h1>数据湖自动化</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                在数据湖架构中对非结构化或半结构化数据进行建模和组合，并将其与传统的 DWH 
                方法相结合或作为补充。必须确保数据模型的整体视图，并为 BI 开发人员/分析师/业务人员提供数据字典。
              </p>
              <h1>方案</h1>
              <p>
                利用 AnalyticsCreator，您可以将传统 DWH 和数据湖的优势结合起来。数据湖模型或目标可使用 
                AnalyticsCreator 构建或建模。AnalyticsCreator 提供基于元数据的数据仓库开发。
                每个源、表、转换或任务的定义都存储在元数据库中，该数据库是开放的，可供进一步使用。用户可以完全访问元数据，
                无需使用 SQL 脚本即可直接对其进行操作。AnalyticsCreator 元数据库的数据结构非常简单，用户界面友好，
                可用于数据 Lakehouse 架构。
              </p>
            </div>
            <img src={lakehouse} className="details-img" alt="" />
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <img src={exportes} className="details-img" alt="" />
            <div>
              <h1>数据导出</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                有时有必要在外部使用 DWH 的数据，无论是在其他分析数据库、BLOB 存储、云存储中，
                还是简单的文本文件中以进一步处理它们。或者，您可能希望在数据仓库中计算的数据写回源数据库。
                例如，可以在数据仓库中计算客户债务限额，或使用 Microsoft 提供的 AI 函数来创建假设，
                然后要在操作系统中重用这些假设。
              </p>
              <h1>方案</h1>
              <p>
                AnalyticsCreator 生成 SQL Server Integration Services （SSIS） 包和 
                Azure 数据工厂管道，以将数据从数据仓库导出到外部数据库。AnalyticsCreator 支
                持此导出的任何 OLEDB 或 ODBC 驱动程序功能。合适的驱动程序会将数据写入目标系统。
                还支持 CSV、文本文件和 Azure Blob 存储。导出到 Azure Blob 存储时，可以使用 CSV、Parquet 和 Avro 格式。
              </p>
            </div>
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <div>
              <h1>TABLEAU推送</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                将 Tableau 用作分析前端技术时，必须将其与数据源（如数据仓库）或结构化或非结构化形式的
                任何源数据连接起来。Tableau 提供了数据转换的可能性，使您能够以您希望在前端使用数据的
                方式转换数据。但是，在没有 DWH 的情况下使用 Tableau 可能会导致质量显著下降。分析项目
                中最耗时的部分是数据准备，这占用了大约 60-80% 的项目时间。这就是为什么自动化如此有意义。
              </p>
              <h1>方案</h1>
              <p>
                AnalyticsCreator管理数据仓库的整个设计、开发和部署过程。具有 KPI 的分析模型也包含在数据仓库中。
                此“最后一层”会自动推送为适合 Tableau 的格式。借助 Tableau，您只需打开文件/项目，即可使用完整的
                模型进行分析。可以直接访问数据仓库中的数据，也可以将数据从数据仓库中提取出来。DWH 中的更改将推送到 
                Tableau，而无需手动操作。公认的数据设计原则要求仓库中的整个数据模型;否则，您正在走一条风险更高的道路。
              </p>
            </div>
            <img src={push} className="details-img" alt="" />
          </div>
        </div>
        <div className="second-content">
          <div className="Details">
            <img src={synapse} className="details-img" alt="" />
            <div>
              <h1>AZURE SYNAPSE 核心技术分析</h1>
              <div className="dividing-line"></div>
              <h1>挑战</h1>
              <p>
                在某些项目中，查询速度和大量数据存在重大挑战。在这些情况下，使用Microsoft专用 
                Synapse 版本是有意义的。但是，也应考虑一些缺点。
              </p>
              <h1>方案</h1>
              <p>
                在AnalyticsCreator中，你有一个整体数据模型，是否为Azure SQL或
                Azure Synapse生成数据库并不重要。您可以开始建模，并在决定目标平台时稍后做出决定。
                可以先从 Azure SQL 开始，然后再切换到 Azure Synapse
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex" style={{ marginTop: '50px' }}>
        <div className="pro_new_tittle">
          <h1>最新消息</h1>
        </div>
        <AnalyNews />
      </div>
    </div>
  );
};

export default AnalyCreator;
