import React, { useEffect, useState} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import phone from "@images/电话.png";
import sendout from "@images/发送.png";
import xinfeng from "@images/信封.png";
import euci from "@images/euci.png";
import "@css/header.css";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [defaultKey, setDefaultKey] = useState("");
  const [width, setWidth] = useState("");
  
  const pathName = window.location.href.substring(
    0,
    window.location.href.lastIndexOf("/")
  );
  const [path, setPath] = useState(pathName);

  const [routeKey, setRouteKey] = useState("");

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    setDefaultKey(currentPath.slice(1).split('?')[0]);
    let path = location.pathname;
    let key = path.split("/")[1];
    if (key === "home") {
      setRouteKey("1");
    } else if (key === "company") {
      setRouteKey("2");
    } else if (key === "product") {
      setRouteKey("3");
    } else if (key === "news") {
      setRouteKey("4");
    } else if (key === "technology") {
      setRouteKey("5");
    } else if (key === 'relation') {
      setRouteKey('6');
    }

    if (key === "detail") {
      const winUrlHeader = window.location.href;
      const typeName = winUrlHeader.split('/')[4];
      let type = typeName.substring(typeName.indexOf('?'));
      if (type === '?type=home') {
        setDefaultKey('home');
        console.log(defaultKey);
      } else if (type === '?type=product' || type === '?type=product?contact' || type === '?type=product?3dvs') {
        setDefaultKey('product');
        console.log(defaultKey);
      } else if (type === '?type=news') {
        setDefaultKey('news');
        console.log(defaultKey);
      } else if (type === '?type=technology') {
        setDefaultKey('technology');
        console.log(defaultKey);
      }
    }

    window.addEventListener("resize", function () {
      setWidth(window.innerWidth);
    });
  }, [window.location.href, location.pathname, width]);

  const handleClick = (e) => {
    navigate("/" + e.key);
    setDefaultKey(e.key);
    if (e.key === 'product') {
      navigate('/product?contact')
    }
  };

  const onClickScro = () => {
    window.scrollTo({
      top: 30000,
      behavior: "smooth",
    });
  };

  const items = [
    {
      key: '1',
      label: (
          <a href={path + "/home"}>
            <span>首页</span>
          </a>
      )
    },
    {
      key: '2',
      label: (
          <a href={path + "/company"}>
            <span> 公司</span>
          </a>
      )
    },
    {
      key: '3',
      label: (
        <a href={path + "/product?contact"}>
          <span> 产品</span>
        </a>
      )
    },
    {
      key: '4',
      label: (
        <a href={path + "/news" }>
          <span> 新闻</span>
        </a>
      )
    },
    {
      key: '5',
      label: (
        <a href={path + "/technology"}>
          <span> 技术中心</span>
        </a>
      )
    },
    {
      key: '6',
      label: (
        <a href={path + "/relation"}>
          <span> 联系我们</span>
        </a>
      )
    },
  ];
  const dropClick = (open, info) => {
    console.log(open);
    console.log(info);
  };
  return (
    <div className="app flex">
      <div className="top flex">
        <div className="top_relation">
          <span>
            <img src={phone} alt="" />
            +86 021 37708778
          </span>
          <span>
            <img src={xinfeng} alt="" />
            info@euci-software.com
          </span>
          <span onClick={() => onClickScro()} className="relation">
            <img src={sendout} alt="" />
            需要联系我
          </span>
        </div>
      </div>
      <div className="header center" onClick={() => {}}>
        <div className="logo_box">
          <img src={euci} className="logo" alt="" />
        </div>
        {/* pc */}
        {document.body.clientWidth > 1000 && (
          <Menu
            onClick={handleClick}
            selectedKeys={[defaultKey]}
            mode="horizontal"
          >
            <Menu.Item key="home">首页</Menu.Item>
            <Menu.Item key="company">公司</Menu.Item>

            <Menu.Item key={"product"}>产品</Menu.Item>
            <Menu.Item key="news">新闻</Menu.Item>
            <Menu.Item key="technology">技术中心</Menu.Item>
            <Menu.Item key="relation">联系我们</Menu.Item>
          </Menu>
        )}
        {/* 手机 */}
        {document.body.clientWidth < 1000 && (
          <Dropdown menu={{ items }} onOpenChange={dropClick} trigger={[ 'click' ]}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <MenuUnfoldOutlined />
              </Space>
            </a>
          </Dropdown>
        )}
      </div>
    </div>
  );
};

export default Header;
