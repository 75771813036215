import React, { useEffect, useState, useCallback } from "react";
import "@css/product/pro_carousel.css";
import "@css/product/vs_contact.css";
import threeblue from "@images/three-blue.png";
import blue from "@images/blue.png";
import threeorange from "@images/three-orange.png";
import threepink from "@images/three-pink.png";
import white from "@images/white.png";
import zhi from "@images/white-zhi.png";
import bigcontact from "@images/bigcontact.png";
import contact from "@images/contact.png";
import Analy from '../assets/images/analy/analy-product.svg';
import AnalyCreator from '@components/Analy-creator';
import VsContact from "@components/Vs_contact";
import PowerWork from '@components/power-work';
import ProductWorld from "@components/Product_world";
import ProductWidely from "@components/Product_widely";
import ProductText from "@components/Product_text";
import ProductApproval from "@components/Product_approval";
import contactvideo from "@images/contactvideo.mp4";
import ThreeText from "@components/ThreeText";
import pronew from "@images/pronew.png";
import automovie from "@images/automovie.jpg";
import Slider from "react-slick";
import loading from "@images/loading.png";
import { Link } from "react-router-dom";

import ReactPlayer from "react-player";
import { productImg } from "../contanst";
import { LightweightTitle } from "../contanst";

import { getProCar } from "../utils";

const ProductcCar = () => {
  const [menuSwitch, setMenuSwitch] = useState(0);
  const [carList, setCarList] = useState([]);
  const [key, setKey] = useState(0);
  const [load, setLoad] = useState("");
  const [show1, setShow] = useState(0);
  const [videshow, setVideshow] = useState(0);
  const [winUrlSplit, setWinUrlSplit] = useState('');

  const logoimg = [
    { img: blue, name: "contact" },
    { img: Analy, name: "analy" },
    { img: white, name: "3dvs" },
    { img: zhi, name: "powerwork" },
  ];
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const onClickShow = (i) => {
    setShow(i);
  };

  //图标菜单切换
  const onClickScro = (i) => {
    // console.log(i);
    setMenuSwitch(i);
    setVideshow(1);
  };

  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])

  const onClickPic = (key) => {
    // console.log(key);
    setKey(key);
  };
  const pic = [
    { img: threeblue, before: 0, bigimg: bigcontact },
    { img: threeorange, before: 0, bigimg: threeorange },
    { img: threepink, before: 0, bigimg: threepink },
  ];
  const show = useCallback((cur) => {
    return key === cur ? "active" : "procontact";
  });
  useEffect(() => {
    setLoad(false);
    getProCar()
      .then((res) => {
        setCarList(res.Data);
      })
      .finally(() => {
        setLoad(true);
      });

    if (localStorage.getItem("jump") !== "") {
      setMenuSwitch(Number(localStorage.getItem("jump")));
      localStorage.setItem("jump", 0);
    } else {
      setMenuSwitch(0);
    }
  }, []);
  useEffect(() => {
    const url = window.location.search;
    if (url.slice(1) === "contact") {
      setMenuSwitch(0);
    } else if (url.slice(1) === 'analy') {
      setMenuSwitch(1);
    } else if (url.slice(1) === "3dvs") {
      setMenuSwitch(2);
    } else {
      setMenuSwitch(3);      
    }
    console.log(url);
  }, []);
  return (
    <div className="flex">
      <div className="product_logo">
        <div className="pro_menu">
          {logoimg?.map((item, i) => {
            return (
              <div key={i} className="pro_menu_box">
                <Link to={`/product?${item.name}`}>
                  <img
                    src={item.img}
                    alt=""
                    onClick={() => onClickScro(i)}
                    className={i === menuSwitch ? "check_img" : "product_logo_img"}
                  />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {(() => {
        if (menuSwitch === 0) {
          return (
            // <div>
            <div className="flex">
              <div className="pro_box">
                <div className="pro">
                  {load ? (
                    <Slider {...settings}>
                      {carList?.map((item, i) => {
                        console.log(item, 'item=------------');
                        const urlPro = item.LinkNewsFullUrl;
                        const urles = `?type=${winUrlSplit}`;
                        return (
                          <div key={i}>
                            <a href={urlPro+urles} target={"_blank"} rel="noreferrer">
                              <div className="procar_text">
                                <div className="procar_text_box">
                                  <p className="procar_text_desc">
                                    <span className="procar_title">
                                      {item.AdvTitle}
                                    </span>
                                    {item.AdvDesc}
                                  </p>
                                </div>
                              </div>
                            </a>
                            <img
                              src={item.AdvImageFullUrl}
                              className="pro_big"
                              alt=""
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  ) : (
                    <div className="pro_car_loading">
                      <img src={loading} alt="" />
                      <p>加载中...</p>
                    </div>
                  )}

                  <div className="flex">
                    <div className="pro_text">
                      <img src={contact} className="pro_contact" alt="" />
                      <div className="pro_yige">
                        <span>熠格是中国唯一深度技术和总渠道合作伙伴</span>
                        <a href="www.contact-software.com" target="_blank">
                          官网直达
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contact_content">
                <div className="pro_con_big flex">
                  <div className="con_center">
                    <div className="con_logo">
                      <div className="video_box">
                        <ReactPlayer
                          controls
                          url={contactvideo}
                          playing={true}
                          muted={true}
                          className={
                            menuSwitch === 0 ? "contact_video" : "video_none"
                          }
                        />
                      </div>
                      <div className="con_logo_text">
                        <h1>产品生命周期管理方案30年</h1>
                        <p className="con_title">
                          CONTACT
                          Software来自德国布莱梅，于1990年成立，其CONTACT
                          Elements低代码平台和CIM
                          Database的PLM产品在业界具有广泛的知名度和应用。
                        </p>
                        <p className="con_text">
                          使命：激活伟大的想法，我们创造了一个工作环境，员工可以将他们的技能专注在提供更好的产品和服务上，而不是僵化的系统。在协作运行更顺畅的地方，流程变得更加高效，日常工作变得更加容易。骨干平台提供了安全性和自由度，允许个人和团队在复杂项目中进行敏捷交互。
                        </p>
                        <p className="con_text">
                          Mission：Energizing great minds, Instead of rigid
                          systems, we create a working world in which employees
                          can concentrate their skills on providing better
                          products and services. Where collaboration runs more
                          smoothly, processes become more efficient and routine
                          work becomes easier. And where a guiding framework
                          provides both the security and freedom that allows
                          agile interaction between individuals and teams in
                          complex projects.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="con_three_box">
                <div className="con_three flex">
                  {pic?.map((item, i) => {
                    return (
                      <div onClick={() => onClickPic(i)} key={i}>
                        <img src={item.img} alt="" />
                        {i === key && !item.before ? (
                          <div className="con_bef"></div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="morepic">
                <div className="morepic_box">
                  {productImg?.map((item, index) => {
                    return (
                      <div key={index} className={show(index)}>
                        <div className="pro_littleimg_box">
                          {item.imglist.map((item, index) => {
                            return (
                              <div key={index} className="pro_littleimg">
                                <img src={item.img} alt="" />
                                <p className="morepic_text">{item.l1}</p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <ProductWorld />
              <div className="pro_Lightweight">
                {LightweightTitle?.map((item, i) => {
                  return (
                    <div key={i} className="lightweight_title">
                      <h1
                        onClick={() => onClickShow(i)}
                        className={
                          i === show1
                            ? "pro_Lightweight_one"
                            : "pro_Lightweight_two"
                        }
                      >
                        {item.title}
                      </h1>
                    </div>
                  );
                })}
                {LightweightTitle?.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className={
                        i === show1
                          ? "pro_Lightweighttext"
                          : "pro_Lightweightnone"
                      }
                    >
                      {item.text}
                    </div>
                  );
                })}
              </div>
              {show1 ? <ProductWidely /> : <ProductText />}
              <ProductApproval />
              <div className="flex">
                <div className="pro_new_box">
                  <div className="pro_new_tittle">
                    <h1>最新消息</h1>
                  </div>
                  <ThreeText />
                  <div className="pro_new_img">
                    <img src={pronew} alt="" />
                    <img src={automovie} className="automovie" alt="" />
                  </div>
                </div>
              </div>
            </div>
            // </div>
          );
        } else if (menuSwitch === 1) {
          return (
            <AnalyCreator />
          )
        } else if (menuSwitch === 2) {
          return (
            <div>
              <VsContact />
            </div>
          );
        } else if (menuSwitch === 3) {
          return (
            <div>
              <PowerWork />
            </div>
          )
        }
      })()}
    </div>
  );
};

export default ProductcCar;
