import React from 'react';
import '../../assets/css/product/customer.css'
import { onlyCar } from '../../contanst/pro_customer';

const OnlyCar = () => {
    return (
        <div className='pro_customer_Exhibition1'>
            {
                onlyCar.map((item, index) => {
                    return (
                        <div key={index}  >
                            <img src={item.pic} className='customer_img' />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default OnlyCar;
