// 汽车
import shouqi from '@images/customer/car30/shouqi.png'
import BWM from '@images/customer/car30/1BMW.png';
import subaru from '@images/customer/car30/2subaru.png';
import toyota from '@images/customer/car30/3toyota.png';
import hyundai from '@images/customer/car30/4hyundai.png';
import honda from '@images/customer/car30/5honda.png';
import CONTI from '@images/customer/car30/6CONTINENTAL.png';
import ZF from '@images/customer/car30/7ZF.png';
import MANN from '@images/customer/car30/8MANN.png';
import BASF from '@images/customer/car30/9BASF.png';
import KOSTAI from '@images/customer/car30/10KOSTAL.png';
import AL from '@images/customer/car30/AL.png';
import BOSAL from '@images/customer/car30/BOSAL.png';
import EBER from '@images/customer/car30/EBER.png';
import FAYMON from '@images/customer/car30/FAYMON.png';
import flaig from '@images/customer/car30/flaig.png';
import hofer from '@images/customer/car30/hofer.png';
import HYMER from '@images/customer/car30/HYMER.png';
import KACO from '@images/customer/car30/KACO.png';
import kiekerts from '@images/customer/car30/kiekerts.png';
import KIRCHHOFF from '@images/customer/car30/KIRCHHOFF.png';
import KUSTER from '@images/customer/car30/KUSTER.png';
import linde from '@images/customer/car30/linde.png';
import MAN from '@images/customer/car30/MAN.png';
import RONALGROUP from '@images/customer/car30/RONALGROUP.png';
import SAARGUMMI from '@images/customer/car30/SAARGUMMI.png';
import SGF from '@images/customer/car30/SGF.png';
import SITECH from '@images/customer/car30/SITECH.png';
import SUMMITIMI from '@images/customer/car30/SUMITIMI.png';
import THYSSENKRUPP from '@images/customer/car30/THYSSENKRUPP.png';
import WITTE from '@images/customer/car30/WITTE.png';
import more from '@images/customer/car30/more.png'
// 高科技电子
import ABUS from '@images/customer/High-tech10/ABUS.png';
import ACTIMED from '@images/customer/High-tech10/ACTIMED.png';
import BENNING from '@images/customer/High-tech10/BENNING.png';
import LPKF from '@images/customer/High-tech10/LPKF.png';
import OLYMPUS from '@images/customer/High-tech10/OLYMPUS.png';
import RECARO from '@images/customer/High-tech10/RECARO.png';
import SENNHEISER from '@images/customer/High-tech10/SENNHEISER.png';
import SIG from '@images/customer/High-tech10/SIG.png';
import STIEBEL from '@images/customer/High-tech10/STIEBEL.png';
import WEIDMULLER from '@images/customer/High-tech10/WEIDMULLER.png';
// 通用机械
import AIP from '@images/customer/Mechanics10/AIP.png';
import ANDRITZ from '@images/customer/Mechanics10/ANDRITZ.png';
import BOSCH from '@images/customer/Mechanics10/BOSCH.png';
import ENERCON from '@images/customer/Mechanics10/ENERCON.png';
import KAMPF from '@images/customer/Mechanics10/KAMPF.png';
import MIXACO from '@images/customer/Mechanics10/MIXACO.png';
import SELEX from '@images/customer/Mechanics10/SELEX.png';
import SIEMPELKAMP from '@images/customer/Mechanics10/SIEMPELKAMP.png';
import SYNTEGON from '@images/customer/Mechanics10/SYNTEGON.png';
import TEAMTECHNIK from '@images/customer/Mechanics10/TEAMTECHNIK.png'
// 交通
import AVG from '@images/customer/traffic8/AVG.png';
import BUNDESANTRALT from '@images/customer/traffic8/BUNDESANTRALT.png';
import DB from '@images/customer/traffic8/DB.png';
import EUROGATE from '@images/customer/traffic8/EUROGATE.png';
import EWE from '@images/customer/traffic8/EWE.png';
import HOCHBAHN from '@images/customer/traffic8/HOCHBAHN.png';
import SSB from '@images/customer/traffic8/SSB.png';
import TRANSTEC from '@images/customer/traffic8/TRANSTEC.png'

const Car = [
    { pic: BWM },
    { pic: subaru },
    { pic: toyota },
    { pic: hyundai },
    { pic: honda },
    { pic: CONTI },
    { pic: ZF },
    { pic: MANN },
    { pic: BASF },
    { pic: KOSTAI },
    { pic: AL },
    { pic: BOSAL },
    { pic: EBER },
    { pic: FAYMON },
    { pic: flaig },
    { pic: hofer },
    { pic: HYMER },
    { pic: KACO },
    { pic: kiekerts },
    { pic: KIRCHHOFF },
    { pic: KUSTER },
    { pic: linde },
    { pic: MAN },
    { pic: RONALGROUP },
    { pic: SAARGUMMI },
    { pic: SGF },
    { pic: SITECH },
    { pic: SUMMITIMI },
    { pic: THYSSENKRUPP },
    { pic: more ,isShow:false},
]
const CarTotal = [
    { pic: BWM },
    { pic: subaru },
    { pic: toyota },
    { pic: hyundai },
    { pic: honda },
    { pic: CONTI },
    { pic: ZF },
    { pic: MANN },
    { pic: BASF },
    { pic: KOSTAI },
    { pic: AL },
    { pic: BOSAL },
    { pic: EBER },
    { pic: FAYMON },
    { pic: flaig },
    { pic: hofer },
    { pic: HYMER },
    { pic: KACO },
    { pic: kiekerts },
    { pic: KIRCHHOFF },
    { pic: KUSTER },
    { pic: linde },
    { pic: MAN },
    { pic: RONALGROUP },
    { pic: SAARGUMMI },
    { pic: SGF },
    { pic: SITECH },
    { pic: SUMMITIMI },
    { pic: THYSSENKRUPP },
    { pic: WITTE },
    { pic: ABUS },
    { pic: ACTIMED },
    { pic: BENNING },
    { pic: LPKF },
    { pic: OLYMPUS },
    { pic: RECARO },
    { pic: SENNHEISER },
    { pic: SIG },
    { pic: STIEBEL },
    { pic: WEIDMULLER },
    { pic: AIP },
    { pic: ANDRITZ },
    { pic: BOSCH },
    { pic: ENERCON },
    { pic: KAMPF },
    { pic: MIXACO },
    { pic: SELEX },
    { pic: SIEMPELKAMP },
    { pic: SYNTEGON },
    { pic: TEAMTECHNIK },
    { pic: AVG },
    { pic: BUNDESANTRALT },
    { pic: DB },
    { pic: EUROGATE },
    { pic: EWE },
    { pic: HOCHBAHN },
    { pic: SSB },
    { pic: TRANSTEC },
    { pic: shouqi },
]
const onlyCar = [
    { pic: BWM },
    { pic: subaru },
    { pic: toyota },
    { pic: hyundai },
    { pic: honda },
    { pic: CONTI },
    { pic: ZF },
    { pic: MANN },
    { pic: BASF },
    { pic: KOSTAI },
    { pic: AL },
    { pic: BOSAL },
    { pic: EBER },
    { pic: FAYMON },
    { pic: flaig },
    { pic: hofer },
    { pic: HYMER },
    { pic: KACO },
    { pic: kiekerts },
    { pic: KIRCHHOFF },
    { pic: KUSTER },
    { pic: linde },
    { pic: MAN },
    { pic: RONALGROUP },
    { pic: SAARGUMMI },
    { pic: SGF },
    { pic: SITECH },
    { pic: SUMMITIMI },
    { pic: THYSSENKRUPP },
]
const high= [
    { pic: ABUS },
    { pic: ACTIMED },
    { pic: BENNING },
    { pic: LPKF },
    { pic: OLYMPUS },
    { pic: RECARO },
    { pic: SENNHEISER },
    { pic: SIG },
    { pic: STIEBEL },
    { pic: WEIDMULLER }
]
const mechanics= [
    { pic: AIP },
    { pic: ANDRITZ },
    { pic: BOSCH },
    { pic: ENERCON },
    { pic: KAMPF },
    { pic: MIXACO },
    { pic: SELEX },
    { pic: SIEMPELKAMP },
    { pic: SYNTEGON },
    { pic: TEAMTECHNIK }
]
const traffic= [
    { pic: AVG },
    { pic: BUNDESANTRALT },
    { pic: DB },
    { pic: EUROGATE },
    { pic: EWE },
    { pic: HOCHBAHN },
    { pic: SSB },
    { pic: TRANSTEC }
]
const List = [
    { li: '全部' },
    { li: '汽车' },
    { li: '高科技电子' },
    { li: '通用机械' },
    { li: '交通运输' },
]
export {
    List,
    Car,
    high,
    mechanics,
    traffic,
    CarTotal,
    onlyCar
}