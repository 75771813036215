import React, { useEffect, useState } from "react";
import "@css/detail/detail.css";
import { useParams } from "react-router-dom";
import { getNewsDetail } from "../../utils/index";
import DocumentTitle from "react-document-title";

const Content = () => {
  let params = useParams();
  const [id, setId] = useState(params.id);
  const [NewsContent, setNewsContent] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAhor] = useState("");
  const [NewsPublisherName, setNewsPublisherName] = useState("");
  const [NewsDate, setNewsDate] = useState("");
  const [NewsStatus, setNewsStatus] = useState("");

  useEffect(() => {
    getNewsDetail(id).then((res) => {
      // console.log(res.Data);
      setNewsContent(res.Data.NewsContent);
      setTitle(res.Data.NewsTitle);
      setAhor(res.Data.NewsAuthor);
      setNewsPublisherName(res.Data.NewsPublisherName);
      setNewsDate(res.Data.NewsDate);
      setNewsStatus(res.Data.NewsStatus);
    });
    console.log(id);
  }, [id]);
  return (
    <DocumentTitle title={title}>
      <div className="content_big">
        
        <div className="news_title">{title}</div>
        <div className="news_message">
          <div>{NewsDate.slice(0, 11)}</div>
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: NewsContent }}
          className="news_content"
        ></div>
      </div>
    </DocumentTitle>
  );
};

export default Content;
