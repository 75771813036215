import React from "react";
import "@css/product/pro_world.css";
import con_element from "@images/con_element.png";
import { WorldText } from "../contanst";

const ProductWorld = () => {
  return (
    <div className="flex">
      <div className="world_box">
        <div className="world_text">
          <div className="world_img">
            <img src={con_element} alt="" />
          </div>
          <div className="world_text_box">
            <h1>优秀的工程业务构建平台</h1>
            <p>
              Elements拥有开放性、通用性、稳定性的底层平台技术服务支持，企业可以快速构建扩展新的独立业务模块也可以容易的整合进已有的内部业务系统。
              <i className="iconfont icon-gengduo"></i>
            </p>
          </div>
          <div className="world_logobox">
            {WorldText.map((item, index) => {
              return (
                <div key={index}>
                  <img src={item.img} alt="" />
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductWorld;
