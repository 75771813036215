import React from "react";
import ProductcCar from "../components/Product_car";
import "../assets/css/product/viewstation.css";
import vs from "@images/vs.png";
import VsContact from "../components/Vs_contact";
import ProductText from "../components/Product_text";
import ThreeText from "../components/ThreeText";
import Footer from "../components/Footer";
import ProductCustomer from "../components/Product_customer";
import Slider from "react-slick";

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Typography } from 'antd';
const menu = (
  <Menu
    selectable
    defaultSelectedKeys={['3']}
    items={[
      {
        key: '1',
        label: 'Item 1',
      },
      {
        key: '2',
        label: 'Item 2',
      },
      {
        key: '3',
        label: 'Item 3',
      },
    ]}
  />
);
const Viewstation = () => {
  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Dropdown overlay={menu}>
      <Typography.Link>
        <Space>
          Selectable
          <DownOutlined />
        </Space>
      </Typography.Link>
    </Dropdown>
  );
};

export default Viewstation;
