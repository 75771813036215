import React from "react";
import { useState, useCallback, useEffect } from "react";
import "../assets/css/product/vs_contact.css";
import table from "@images/tabble.png";
import change from "@images/change.png";
import VR from "@images/VR.png";
// import bigtabble from "@images/bigtabble.svg";
import web from "@images/web.png";
import vs from "@images/vs.png";
import viewsstation from "@images/viewsstation.mp4";
import ReactPlayer from "react-player";
// import twoweb from "@images/two-web.png";
// import threeKAS from "@images/threeKAS.png";
// import fourvr from "@images/fourvr.png";
import ThreeText from "../components/ThreeText";
import { VsEdition } from "../contanst";
import VsText from "./Vs_text";
import Slider from "react-slick";
import loading from "@images/loading.png";

import { getProVs } from "../utils";

const VsContact = () => {
  const [current, setCurrent] = useState(0);
  const [carList, setCarList] = useState([]);
  const [load, setLoad] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');
  const onClickPic = (key) => {
    // console.log(key);
    setCurrent(key);
  };

  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])
  const pic = [
    { img: table, before: 0, tittle: "桌面版" },
    { img: web, before: 0, tittle: "web版本" },
    { img: change, before: 0, tittle: "转换器" },
    { img: VR, before: 0, tittle: "虚拟显示VR" },
  ];
  // const showPic = [
  //   { img: bigtabble },
  //   { img: twoweb },
  //   { img: threeKAS },
  //   { img: fourvr },
  // ];
  const show = useCallback((cur) => {
    return current === cur ? "active" : "vs_bigpic";
  }, [current]);
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    setLoad(false);
    getProVs()
      .then((res) => {
        setCarList(res.Data);
      })
      .finally(() => {
        setLoad(true);
      });
  }, []);
  return (
    <div>
      <div className="flex">
        <div className="pro">
          {load ? (
            <Slider {...settings}>
              {carList.map((item, i) => {
                const urlPro = item.LinkNewsFullUrl;
                const urles = `?type=${winUrlSplit}`;
                return (
                  <div key={i}>
                    <a href={urlPro+urles} target={"_blank"} rel="noreferrer">
                      <div className="varcar_text">
                        <div className="varcar_text_box">
                          <p className="varcar_text_desc">
                            <span className="varcar_text_title">
                              {item.AdvTitle}
                            </span>
                            {item.AdvDesc}
                          </p>
                        </div>
                      </div>
                    </a>
                    <img src={item.AdvImageFullUrl} className="var_big" alt="" />
                  </div>
                );
              })}
            </Slider>
          ) : (
            <div className="pro_car_loading">
              <img src={loading} alt="" />
              <p>加载中...</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="vs_text">
          <img src={vs} className="pro_vsimg" alt="" />
          <div className="pro_vs">熠格是中国区指定唯一渠道分销合作伙伴</div>
          <p>
            <a href="https://viewer.kisters.de/en/index.html " target="_blank" rel="noreferrer">
              官网直达
            </a>
            <a href="https://viewer.kisters.de/en/contact.html" target="_blank" rel="noreferrer">
              查看合作资源
            </a>
          </p>
        </div>
      </div>
      <div className="vs_long_text ">
        <div className="vs_long flex">
          <div className="vs_contact_center">
            <ReactPlayer
              controls
              url={viewsstation}
              playing={true}
              muted={true}
              className="contact_video"
            />
            <div className="vs_contact_right">
              <div className="vs_contact_right_text">
                <h1>专注可视化方案25年</h1>
                <p>
                  来自德国Aachen
                  KISTERS集团下的Viewstation在可视化已经深耕25年多,凭借出色的兼容性
                  高性能表现在全世界范围拥有广泛的用户
                </p>
              </div>

              <div className="vs_contact_btn">
                <div>
                  <p>用户</p>
                  <button>&gt;300000</button>
                </div>
                <div>
                  <p>读取格式</p>
                  <button>&gt;100</button>
                </div>
                <div>
                  <p>读取500M</p>
                  <button>&lt;4s</button>
                </div>
                <div>
                  <p>经验</p>
                  <button>&gt;25y</button>
                </div>
                <div>
                  <p>培训</p>
                  <button>0</button>
                </div>
                <div>
                  <p>文件缓存</p>
                  <button>0</button>
                </div>
              </div>
            </div>
          </div>

          <div className="vs_contact_background">
            <div className="vs_contact_four">
              {pic.map((item, i) => {
                return (
                  <div
                    onClick={() => onClickPic(i)}
                    key={i}
                    className="vs_four_text"
                  >
                    <div>
                      <p>{item.tittle}</p>
                      <img src={item.img} className="vsfour_img" alt="" />
                      {i === current && !item.before ? (
                        <div className="vs_before"></div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {VsEdition.map((item, index) => {
        return (
          <div className="vs_contact_pic" key={index}>
            <div className={show(index)}>
              <img src={item.img} alt="" />
            </div>
            <div className="Free_trial">
              <div className={show(index)}>
                <h1>{item.title}</h1>
                <p>
                  <span>{item.text}</span>
                </p>
                {/* <button>免费试用</button> */}
              </div>
            </div>
          </div>
        );
      })}
      <div className="vs_xian"></div>
      <VsText />
      <div className="flex">
        <div className="pro_new_tittle">
          <h1>最新消息</h1>
        </div>
        <ThreeText />
      </div>
    </div>
  );
};

export default VsContact;
