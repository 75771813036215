import React, { useEffect, useState } from "react";
import "@css/newstech.css";
import { getNewsCar, getTechCar } from "../utils/index";
import { Link } from "react-router-dom";
import loading from "@images/loading.png";


const Newstech = (props) => {
  const { data, datas } = props;
  const [news, setNews] = useState([]);
  const [tech, setTech] = useState([]);
  const [id, setId] = useState("");
  const [load, setLoad] = useState("");
  const [winUrlSplit, setWinUrlSplit] = useState('');

  const onClickId = (id) => {
    localStorage.setItem("route", "news");
    // console.log(id);
    setId(id);
  };
  const onClickIds = (id) => {
    localStorage.setItem("route", "technology");
    // console.log(id);
    setId(id);
  };

  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])
  useEffect(() => {
    setLoad(false);
    getNewsCar()
      .then((res) => {
        if (!res.Message) {
          // console.log(res.Data);
          setNews(res.Data);
        }
      })
      .catch((err) => {
        // console.log(err, "##############");
      })
      .finally(() => {
        setLoad(true);
      });

    getTechCar()
      .then((res) => {
        if (!res.Message) {
          setTech(res.Data);
        }
      })
      .finally(() => {
        setLoad(true);
      });
  }, []);
  return (
    <>
      {load ? (
        <div className="content_box">
          {data &&
            news.map((item, index) => {
              return (
                <div key={index}
                  // onClick={() => onClickId(item.NewsId)}
                >
                  <Link to={`/detail/${item.NewsId}?type=${winUrlSplit}`} target="_blank">
                    <img src={item.NewsThumbImage} alt="" />
                    <div className="content_box_text">
                      <h1>{item.NewsTitle}</h1>
                      <p>{item.NewsDesc}</p>
                    </div>
                  </Link>
                </div>
              );
            })}
          {datas &&
            tech.map((item, index) => {
              return (
                <div key={index}
                  // onClick={() => onClickIds(item.NewsId)}
                >
                  <Link to={`/detail/${item.NewsId}?type=${winUrlSplit}`} target="_blank">
                    <img src={item.NewsThumbImage} alt="" />
                  </Link>
                  <div className="content_box_text">
                    <h1>{item.NewsTitle}</h1>
                    {/* <p>{item.NewsDesc}</p> */}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="newtech_loading">
          <img src={loading} alt="" />
          <p>加载中...</p>
        </div>
      )}
    </>
  );
};

export default Newstech;
