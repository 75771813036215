import { React, useState, useEffect } from "react";
import Header from "../Header";
import Content from "./Content";
import Footer from "../Footer";
import "@css/product/pro_carousel.css";
import { Breadcrumb } from "antd";
import { useParams } from "react-router-dom";
import { getNewsDetail } from "../../utils/index";

const Detail = () => {
  let params = useParams();
  let id = params.id;
  console.log(params);
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    // if (localStorage.getItem("route") === "news") {
    //   setName("新闻");
    // } else if (localStorage.getItem("route") === "technology") {
    //   setName("技术中心");
    // } else if (localStorage.getItem("route") === "home") {
    //   setName("首页");
    //   console.log(true, '-------');
    // } else if (localStorage.getItem("route") === "product") {
    //   setName("产品");
    // }
    const winUrlHeader = window.location.href;
    const typeName = winUrlHeader.split('/')[4];
    let type = typeName.substring(typeName.indexOf('?'));
    console.log(type);
    if (type === '?type=home') {
      setName('首页');
    } else if (type === '?type=product' || type === '?type=product?contact' || type === '?type=product?3dvs') {
      setName('产品');
    } else if (type === '?type=news') {
      setName('新闻');
    } else if (type === '?type=technology') {
      setName('技术中心');
    }
    getNewsDetail(id).then((res) => {
      setType(res.Data.NewsTypeName);
    });
  },[window.location]);
  return (
    <div>
      <div className="flex">
        <div className="top"></div>
        <Header />
        <div className="bread">
          <Breadcrumb>
            <Breadcrumb.Item>{name}</Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="">{type}</a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Content />
      </div>
      <Footer />
    </div>
  );
};

export default Detail;
