import React from "react";
import "@css/product/pro_text.css";
import vs from "@images/vs.svg";
import { ExtensiveIntegration } from "../contanst";

const ProductWidely = () => {
  return (
    <div className="flex">
      {ExtensiveIntegration.map((item, i) => {
        return (
          <div className="pro_text_box" key={i}>
            <div className="pro_text1" >
              <div className="pro_text_one">
                <div>
                  <img src={item.pic} alt="" />
                </div>
                <div className="pro_text_world">
                  <h1>
                    <span>{item.bold}</span>
                    {item.title}
                  </h1>
                  <p>{item.text}</p>
                  {/* <button>更多信息</button> */}
                </div>
              </div>
              <div className={item.isShow ? "pro_text_two" : "pro_textnone"}>
                <div className="pro_text_two_world">
                  <h1>
                    <span>{item.bold1}</span>
                    {item.title}
                  </h1>
                  <p>{item.text1}</p>
                  {/* <button>更多信息</button> */}
                </div>
                <div>
                  <img src={item.pic1} alt="" />
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductWidely;
