import React from "react";
import ProductcCar from "@components/Product_car";
import ProContact from "@components/Pro_contact";
import "@css/product/database.css";
import ProductText from "@components/Product_text";
import Footer from "@components/Footer";
import ThreeText from "@components/ThreeText";
import ProductCustomer from "@components/Product_customer";
import Header from "@components/Header";
import DocumentTitle from "react-document-title";
import { Link } from "react-router-dom";

const Product = () => {
  return (
    <DocumentTitle title="制造业PLM产品生命周期管理和信息化应用专业解决方案供应商！">
      <div>
        <Header></Header>
        <ProductcCar />
        <div className="flex">
          <div className="pro_costomer_title">
            <h1>客户</h1>
          </div>
          <ProductCustomer />
        </div>

        <Footer />
      </div>
    </DocumentTitle>
  );
};

export default Product;
