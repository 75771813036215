import contact from "@images/1contact.png";
import analyCreate from '../assets/images/analy-computer.png';
import viewstation from "@images/2viewstation.png";
import powerworker from "@images/3powerworker.png";
import customization from "@images/4customization.png";
import AD from '@images/AD.png'

import vs from "@images/2viewstation_logo.svg";
import pw from "@images/3powerworker_blue_logo.svg";
import customer from "@images/4customization_logo.svg";
import contacts from "@images/contact.png";
import Analy from '../assets/images/analy/analy.svg';
import ADlogo from '@images/logo-ad-black.svg'
// 时间轴
import timeone from "@images/2017slider.JPG";
import timetwo from "@images/2019slider.JPG";
import timethree from "@images/2020slider.jpg";
import timefour from "@images/2021slider.JPG";
import transparent from "@images/transparent.gif";
// 客户

// 产品
import little1 from "@images/项目.svg";
import little2 from "@images/little2.svg";
import little3 from "@images/little3.svg";
import little4 from "@images/little4.svg";
import little5 from "@images/little5.svg";
import little6 from "@images/little6.svg";
import little7 from "@images/little7.svg";
import little8 from "@images/little8.svg";
import little9 from "@images/little9.svg";
import little10 from "@images/little10.svg";
import little11 from "@images/little11.svg";
import little12 from "@images/little12.svg";
import little13 from "@images/little13.svg";
import little14 from "@images/little14.svg";
import little15 from "@images/little15.svg";
import little16 from "@images/little16.svg";
import little17 from "@images/little17.svg";
import little18 from "@images/little18.svg";
import little19 from "@images/little19.svg";
import little20 from "@images/little20.svg";
import little21 from "@images/little21.svg";
import little22 from "@images/little22.svg";
import little23 from "@images/little23.svg";
import little24 from "@images/little24.svg";
import little25 from "@images/little25.svg";
import little26 from "@images/little26.svg";
import little27 from "@images/little27.svg";
import little28 from "@images/little28.svg";
import little29 from "@images/little29.svg";
import little30 from "@images/little30.svg";
import little31 from "@images/little31.svg";
import little32 from "@images/little32.svg";
import little33 from "@images/little33.svg";
import little34 from "@images/little34.svg";
import little35 from "@images/little35.svg";
import little36 from "@images/little36.svg";
import little37 from "@images/little37.svg";
import little38 from "@images/little38.svg";
import little39 from "@images/little39.svg";
import little40 from "@images/little40.svg";
import little41 from "@images/little41.svg";
import little42 from "@images/little42.svg";

import hexin from "@images/核心服务.png";
import peizhi from "@images/配置管理.svg";
import wendang from "@images/文档管理.svg";
import qiye from "@images/企业协同.svg";
import gongzuo from "@images/工作流程.svg";
import renwu from "@images/任务管理.svg";
import xiaoxi from "@images/消息协同.svg";
import keshihua from "@images/3D可视化.svg";
import biaoge from "@images/表格报表.svg";
import fenlei from "@images/分类管理.svg";
import kaifang from "@images/开放技术.svg";
import zujiam from "@images/组件架构.svg";
import anquan from "@images/安全.svg";
import fangwen from "@images/访问权限.svg";
import shuzi from "@images/数字签名.svg";
import webserves from "@images/webservers.svg";
import zhandian from "@images/多站点协同.svg";
import jiaohu from "@images/交互体验.svg";
import yun from "@images/云.svg";
import tongxin from "@images/通信协议.svg";
// 高度经验化的
import onejingyan from "@images/1高度经验化.jpg";
import twopeizhi from "@images/2高可配置.jpg";
import threechaoqiang from "@images/3超强交互.png";
import fourqingliang from "@images/4轻量化.png";
import fiveminjie from "@images/5敏捷.jpg";
import sixshequhua from "@images/6社区化.gif";
import MCAD from "@images/2.1MCAD.png";
import ECAD from "@images/2.2ECAD.jpg";
import twothree from "@images/2.3.png";
import ALM from "@images/2.4ALM.png";
import python from "@images/2.5python.jpeg";
// 广泛的业界认可和认证
import CIMdata from "@images/CIMdata.png";
import approvaltwo from "@images/two.png";
import industy from "@images/industy.png";
// vs-contact
import bigtabble from "@images/bigtabble.svg";
import twoweb from "@images/two-web.png";
import threeKAS from "@images/threeKAS.png";
import fourvr from "@images/fourvr.png";
import vsTextone from "@images/vs/1.png";
import VsTexttwo from "@images/vs/2.jpg";
import VsTextthree from "@images/vs/3.jpg";
import VsTextfour from "@images/vs/4.jpg";
// 首页
// 切换logo
const LongLogo = [
  { img: contacts, before: 0 },
  { img: Analy, before: 0 },
  { img: vs, before: 0 },
  { img: pw, before: 0 },
  { img: ADlogo, before: 0 },
  { img: customer, before: 0 },
  
];
// 点击切换文字和图片(长块)
const LongText = [
  {
    img: contact,
    title: "产品生命周期管理系统CIM Database",
    text: "通过32年行业沉淀,德国CONTACT Software研发的针对企业智能数字化产品研发管理平台,以模组化、轻量化、以及开源性更快的融入企业业务系统生态,获得广泛的企业应用。",
    list: [
      {
        l1: "拥有开放的工业管理系统开发平台Elements,企业可以实现自主业务系统的快速开发",
      },
      { l1: "模组化的选配组合可以轻松实现企业阶段性的业务需求" },
      { l1: "海量配置功能和基于Python的二次开发大大降低企业维护的难度" },
      { l1: "轻量客户端和满足现代交互体验的Web应用,加快企业级应用拓展" },
    ],
    but: "关于CIM Database的更多信息",
  },
  {
    img: analyCreate,
    title: "数据仓库专家",
    text: "经过验证、值得信赖的技术自 2004 年以来，已在数百个项目中使用。快速创建数据仓库。连接任何数据源，为任何商业智能前端快速创建分析模型。",
    list: [
      {
        l1: "只需几小时，而不是几天就能取得成果",
      },
      { l1: "它既适合专家，也适合非专家，专家在几天内就能完成一个项目" },
      { l1: "结束永无止境的项目和不断变化的需求的噩梦，您将预先明确项目的时间范围" },
      { l1: "可使用最先进的方法和架构，如数据仓库、云解决方案、实时等" },
      { l1: "随时随地更改整体数据模型，立即生成代码" },
      { l1: "自动化文档和强大的数据线为所有利益相关者带来透明度，自上而下和自下而上的建模" },
    ],
    but: '关于ANALY TICSCREATOR的更多信息'
  },
  {
    img: viewstation,
    title: "三维可视化软件3DViewstation",
    text: "如果需要打开数十G的大型模型,如果有多种三维格式需要处理,如果很多非设计部门的用户期望看到三维,如果期待更加安全的分享设计......",
    list: [
      { l1: "您需要3DViewstation这款软件,拥有庞大企业用户。" },
      { l1: "简单易学习" },
      { l1: "支持上百种三维格式的直接读取" },
      { l1: "拥有桌面版,网页版、VR版本满足不同的读取场景" },
      { l1: "专业转换器可以独立部署" },
      { l1: "丰富强大的API满足不同企业产品的无缝集成" },
    ],
    but: "关于3DViewstation的更多信息",
  },
  {
    img: powerworker,
    title: "制众互联POWERWORKER",
    text: "由EUCI根据客户管理实践联合研发的面向制造业管理赋能的一组灵活易用的解决方案，以“以人为改善之本”为理念，提升企业在组织文化建设、精益生产管理、知识能力储备等细分领域对人的创新赋能。",
    list: [
      { l1: "合理化建议：构建以员工自主参与自我驱动的全过程提案管理系统" },
      { l1: "经验知识管理：构建企业内部进行经验编写、多维度检索的企业级知识内容管理系统" },
      {
        l1: "技能培训系统：满足汽车零部件企业进行操作员工技能质量管理要求，提供在线考核认证复审的标准化培训管理系统",
      },
      {
        l1: "设备运维：提供设备保养规划、保养工单派发执行的全过程设备运维管理",
      },
    ],
    but: "关于POWERWORKER的更多信息",
  },
  {
    img:AD,
    title:'ALTIUM DESIGNER二次开发服务',
    text:'Altium Designer提供统一设计环境，使工程师能够使用单一视图全方位查看从原理图到PCB布局再到设计归档的整个PCB设计过程。在同一个地方访问所有设计工具，使工程师可以在相同的直观环境中完成整个设计过程，并快速交付高质量的产品',
    list:[
      {l1:'电子元器件库管理'},
      {l1:'物料明细表定制'},
      {l1:'第三方数据管理系统接口定制'},
      {l1:'其他特殊功能定制'}
    ],
    // but:'关于AD的更多信息'
  },
  {
    img: customization,
    title: "软件定制化服务",
    text: "熠格具有完整的软件研发团队,服务行业客户近10年,先后交付定制化系统近20多套,软件定制不仅仅对企业量体裁衣,以较低的成本实现优秀的体验。",
    list: [
      { l1: "React、React Native前端技术团队,包括移动APP定制" },
      { l1: "Python后端技术团队" },
      { l1: ".Net传统后端技术团队" },
      { l1: "具有丰富企业业务经验的顾问和产品经理" },
    ],
    // but: "关于软件定制化服务的更多信息",
  },
];
// 首页轮播图图片

// 首页轮播图文字
const carouselText = [
  {
    title: "企业级PLM管理平台",
    word: "CONTACT CIM Database,满足产品开发过程的全部数字化管理需求,高可配置、开源、现代审美的跨平台交互式体验更加适应现代企业级数据管理治理",
  },
  {
    title: "享誉工程界广泛应用的三维可视化软件",
    word: "KISTERS的3DViewstation支持上百种文件格式的直接读取,提升企业内外部的模型可视化体验,2022版本支持发布.",
  },
  {
    title: "工程知识管理系统发布",
    word: "制纵互联品牌新产品发布,提供全面的企业级知识内容管理,高效的创建和标签化画像,加快企业知识累积,以及基于全文检索和相似标签捕捉加快有效知识的发现.",
  },
  {
    title: "合理化建议应用",
    word: "制众互联品牌应用,提供集团企业车间级通过APP和WEB进行灵活的标准提案提交,全过程任务驱动透明自主,统计分析KPI一应俱全,助力企业内部不断持续改善.",
  },
  {
    title: "宁波锦浪科技选择CIM Database",
    word: "光伏行业龙头企业宁波锦浪科技签署CIM Database PLM系统采购合同,用于构建其全产品研发平台,满足日益增量的数据和频繁的数据协调.",
  },
];

// 公司
// 时间轴文字
const TimeText = [
  {
    id: 1,
    text: "与德国CONTACT Software签署合作,共同推进中国区业务和服务跨国企业在华技术支持",
    time: "2017",
    img: contacts,
  },
  {
    text: "德国KISTERS ViewStation中国合作伙伴及产品总代理,面向中国本土企业提供专业的三维可视化工具和集成工程服务",
    time: "2019",
    img: vs,
    id: 2,
  },
  {
    text: "获得国家高新技术企业认定",
    time: "2020",
    img: transparent,
    id: 3,
  },
  {
    text: "获得上市公司英恒集团电子公司上海金脉股权投资",
    time: "2021",
    img: transparent,
    id: 4,
  },
];
// 时间轴右侧图片
const TimeImg = [
  { img: timeone, shows: false, id: 1 },
  { img: timetwo, shows: false, id: 2 },
  { img: timethree, shows: false, id: 3 },
  { img: timefour, shows: false, id: 4 },
];

// 客户

// 产品
// 产品切换小图片
const productImg = [
  {
    imglist: [
      { img: little1, l1: "项目管理" },
      { img: little2, l1: "项目数据" },
      { img: little3, l1: "团队管理" },
      { img: little4, l1: "任务计划" },
      { img: little5, l1: "资源计划" },
      { img: little6, l1: "项目成本" },
      { img: little7, l1: "问题管理" },
      { img: little8, l1: "过程管控" },
      { img: little9, l1: "报表管理" },
      { img: little10, l1: "敏捷看板卡" },
      { img: little11, l1: "产品规划" },
      { img: little12, l1: "需求规格" },
      { img: little13, l1: "产品组合" },
      { img: little14, l1: "产品主数据" },
      { img: little15, l1: "变量管理" },
      { img: little16, l1: "产品成本" },
      { img: little17, l1: "零件管理" },
      { img: little18, l1: "BOM管理" },
      { img: little19, l1: "工程更改" },
      { img: little20, l1: "产品失效" },
      { img: little21, l1: "产品设计" },
      { img: little22, l1: "集成开发环境" },
      { img: little23, l1: "三维可视化" },
      { img: little24, l1: "MCAD集成" },
      { img: little25, l1: "ECAD集成" },
      { img: little26, l1: "CAD文档" },
      { img: little27, l1: "ALM集成" },
      { img: little28, l1: "协同设计" },
      { img: little29, l1: "制造规划" },
      { img: little30, l1: "结构化工艺" },
      { img: little31, l1: "FlowChart" },
      { img: little32, l1: "PFMEA" },
      { img: little33, l1: "CP" },
      { img: little34, l1: "特殊特性" },
      { img: little35, l1: "作业指导书" },
      { img: little36, l1: "生产路径设计" },
      { img: little37, l1: "生产布局管理" },
      { img: little38, l1: "运作主数据" },
      { img: little39, l1: "产品物联IOT" },
      { img: little40, l1: "资产定义" },
      { img: little41, l1: "数据采集" },
      { img: little42, l1: "监控与分析" },
    ],
  },
  {
    imglist: [
      { img: little1, l1: "项目管理" },
      { img: little2, l1: "项目数据" },
      { img: little3, l1: "团队管理" },
      { img: little4, l1: "任务计划" },
      { img: little5, l1: "资源计划" },
      { img: little6, l1: "项目成本" },
      { img: little7, l1: "问题管理" },
      { img: little8, l1: "过程管控" },
      { img: little9, l1: "报表管理" },
      { img: little10, l1: "敏捷看板卡" },
      { img: wendang, l1: "文档管理" },
      { img: gongzuo, l1: "工作流程" },
      { img: renwu, l1: "任务管理" },
      { img: xiaoxi, l1: "消息协同" },
      { img: biaoge, l1: "表格报表" },
    ],
  },
  {
    imglist: [
      { img: little39, l1: "产品物联IOT" },
      { img: little40, l1: "资产定义" },
      { img: little41, l1: "数据采集" },
      { img: little42, l1: "监控与分析" },
    ],
  },
];
// 世界上最优秀的...
const WorldText = [
  { img: hexin, text: "核心服务" },
  { img: peizhi, text: "配置管理" },
  { img: wendang, text: "文档管理" },
  { img: qiye, text: "企业协同" },
  { img: gongzuo, text: "工作流程" },
  { img: renwu, text: "任务管理" },
  { img: xiaoxi, text: "消息协同" },
  { img: keshihua, text: "3D可视化" },
  { img: biaoge, text: "表格报表" },
  { img: fenlei, text: "分类管理" },
  { img: kaifang, text: "开放技术" },
  { img: zujiam, text: "组件架构" },
  { img: anquan, text: "安全" },
  { img: fangwen, text: "访问权限" },
  { img: shuzi, text: "数字签名" },
  { img: webserves, text: "WebServices" },
  { img: zhandian, text: "多站点协同" },
  { img: jiaohu, text: "交互体验" },
  { img: yun, text: "云" },
  { img: tongxin, text: "通信协议" },
];
// 轻量化平台，广泛的集成发展
const LightweightTitle = [
  {
    title: "轻量化平台",
    text: "简洁美观轻客户端以及现代化网页UI交互体验等特性，让企业部署扩展无压力",
  },
  {
    title: "广泛的集成扩展",
    text: "兼容主流CAD和ERP系统，并具备开源开放的兼容性能力",
  },
];
// 高度经验化的...
const HighlyExperienced = [
  {
    pic: onejingyan,
    bold: "高度经验化",
    title: "的管理系统",
    text: "经过30多年的行业广泛实践，CIM Dabatase是内置了大量的标准业务对象数据管理的最佳实践，可以靠不夸张的说我们的系统完成安装到客制化数据的简单部署，用户最快一周就可以使用大部分功能。",
    pic1: twopeizhi,
    bold1: "高可配置",
    title1: "的低代码系统",
    text1:
      "CIM Database的配置高达上千种，从属性扩展、界面布局，业务规则定义等，绝大部分的客户需求无需高级代编程码实现，丰富的可见便可得的配置效果大大降低系统适应性扩展的难度。",
  },
  {
    pic: threechaoqiang,
    bold: "超强交互体验",
    title: "的现代化前端",
    text: "CIM Database的Web应用基于React高级前端语言构建，交互体验和美观设计满足现代化的UX设计理念，同时可以在移动设备上的浏览器进行访问。",
    pic1: fourqingliang,
    bold1: "轻量化",
    title1: "的客户端",
    text1:
      "我的客户端只有200M不到，利用前后端分离技术，更新业务规则的同时无需升级客户端，满足企业级不同用户的使用场景，无需升级电脑配置既可以实现高速运行。",
  },
  {
    pic: fiveminjie,
    bold: "敏捷模组化",
    title: "敏捷模组化部署",
    text: "CIM Database基于Elements平台的模块化应用组合，可以根据企业实际业务的推广进行模块选择性部署",
    pic1: sixshequhua,
    bold1: "唯一社区化",
    title1: "属性支持",
    text1:
      "CIM Database提供了易用的富文本消息内容管理，系统自动发布实时动态信息，用户也可以主动发布信息并参与回复沟通，消息可以附加系统内的任何业务对象数据避免邮件下载分享的不便。",
  },
];
// MCAD软件的集成
const ExtensiveIntegration = [
  {
    pic: MCAD,
    bold: "MCAD软件",
    title: "的集成",
    text: "与主流的3D产品设计软件CATIA、NX、Solidworks、ProE、Creo...一致性集成，协助产品的内外部团队的协同设计：-拥有一致功能的集成能力，所有的集成功能都是原厂统一开发和维护，-支持离线集成模式，在处理大型产品设计时具有较大的优势。-支持系统内的3D模型的可视化功能，无需任何插件安装，方便非设计部门的产品查看。-实现与Dassualt 3DE设计集成",
    pic1: ECAD,
    bold1: "ECAD软件",
    title1: "的集成",
    text1:
      "具有与主流的EDA软件Mentor、AD、Eplan、Zuken...的标准集成功能，支持电子元器件库的PDM的统一管理和本地设计库的同步，对电子设计数据管理的集成管理能力。",
    isShow: 1,
  },
  {
    pic: twothree,
    bold: "ERP软件",
    title: "的集成",
    text: "具有与ERP系统SAP、QAD、RFS系统的标准集成功能，获得SAP官方认证，我们的开放性支持OSLC 和 ReqIF 等开放数据标准、REST 等 API 标准",
    pic1: ALM,
    bold1: "ALM系统",
    title1: "的集成",
    text1:
      "具有与软件学科数据产品整合管理能力，添加软件物料到产品结构中实现特殊物料管理，并实现软件编译文件及主数据与源码管理GitHub和SVN的集成。",
    isShow: 1,
  },
  {
    pic: python,
    bold: "Python高级语言",
    title: "定制化",
    text: "我们的二次开发语言是业界普遍接收的Python开发语言，其拥有海量的第三方库可以让企业快速扩展特殊需求，而开源的特性也让企业管理其二次开发源代码具有独特优势，无惧源码丢失。",
    isShow: 0,
  },
];
// 2021市场分析报告
const Report = [
  {
    title: "广泛的业界认可和认证",
    text: '"CONTACT is one of the providers driving the PLM market [...] addressing the current challenges of the industry with comprehensive solutions. Its open, modular low-code platform Elements and the PLM modules based on it, which companies can combine flexibly, are leading the way here."',
    pic: CIMdata,
  },
];
// 广泛的世界认证和认可
const Authentication = [
  {
    title: "模块化可分割",
    text: "Use new building blocks faster like LEGO and make updates productive earler.",
  },
  {
    title: "开放接口而非封闭",
    text: "Use leading open source sofware and connect diferent systems more easily.",
  },
  {
    title: "配置而非编程",
    text: "mplement your own solutions in the low code platform more agile and easler to maintain.",
  },
  {
    title: "远比其组成更多",
    text: "Carefully selected and coordinated building blocks that work together along the Digital Thread",
  },
];
const Digital = [
  {
    pic: approvaltwo,
    title1:
      "德国经济周刊期刊奖:CONTACT Software 在 2021 年企业数字先锋中排名第二",
    title2: "Digital Pioneers of SMEs 2021",
    title3:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
];
const FamousIndustry = [
  {
    pic: industy,
    title: "著名的工业 4.0 研究与应用中心连续第三次授予Contact Elements ",
    text: "FACTORY SOFTWARE OF THE YEAR",
  },
];
const VsEdition = [
  {
    img: bigtabble,
    before: 0,
    title: "桌面版",
    text: "通用的应用工具软件版本，安装简单，可以更好的利用本地电脑的图形处理性能",
  },
  {
    img: twoweb,
    before: 0,
    title: "网页版",
    text: "随时随地的实现跨设备大型文件的网页浏览，高效查看安全无缓存；同时可以利用接口可以集成到任何的业务系统实现丰富的可视化功能。",
  },
  {
    img: threeKAS,
    before: 0,
    title: "转换器",
    text: "支持后台直接脚本调用命令批量处理文件的转换，可用于需要大量处理文件转换的应用场景",
  },
  {
    img: fourvr,
    before: 0,
    title: "虚拟现实VR",
    text: "支持通过VR虚拟现实设备进行增强查看，并简化提供一组适合虚拟场景下的操作工具，VR对大型模型的可视化提供了更好的第一人称视角。",
  },
];
// vs
const Vs3dText = [
  {
    pic: vsTextone,
    bold: "不同格式",
    title: "的模型文件放入同一场景",
    text: "通过导入NX、CATIA、STP、JT...，可以实现将不同的模型格式文件并入到同一个视图进行组装查看。",
    pic1: VsTexttwo,
    bold1: "压缩转换轻量化",
    title1: "的低代码系统",
    text1:
      "3DViewstation支持将所有的模型导入后转换成自有的3dvs格式，在保留精度和大部分样式的情况下实现文件的轻量化处理，同时查看者无法更改模型实体的参数，确保工程信息安全准确传递。",
  },
  {
    pic: VsTextthree,
    bold: "网页",
    title: "可以随时随地高效展示模型",
    text: "通过web部署，用户可以在不同设备上使用浏览器直接查看发布的模型，得益于流文件处理，任何设备可以轻松浏览大型模型，且没有任何文件缓存和本地安装。",
    pic1: VsTextfour,
    bold1: "全面强大",
    title1: "的API可以嵌入任何业务系统",
    text1:
      "通过功能全面的简单的xml接口，可以将web查看所有功能内嵌到任何的独立业务系统，扩展其在可视化方面的功能。",
  },
];
export {
  LongText,
  carouselText,
  LongLogo,
  TimeText,
  TimeImg,
  productImg,
  WorldText,
  LightweightTitle,
  HighlyExperienced,
  ExtensiveIntegration,
  Authentication,
  Report,
  Digital,
  FamousIndustry,
  VsEdition,
  Vs3dText,
};
