import { React, useEffect, useState } from "react";
import euci from "@images/euci.png";
import "@css/footer.css";
import { Checkbox } from "antd";
import code from "@images/weixinma.png";
import guo from "@images/guo.png";
import axios from "axios";
import { Button, Form, Input, message, Select } from "antd";
import kisters from "@images/kisters.png";
import contact from "@images/contact.png";
import analy from '../assets/images/analy/analy-product.svg';
const Footer = () => {
  const [form] = Form.useForm();
  const [width, setWidth] = useState("");
  const onFinish = (values) => {
    // console.log(values);
    console.log(Number(values.ifSubscribed));
    const data = values;
    values.ifSubscribed = Number(values.ifSubscribed);
    const config = {
      method: "post",
      url: "https://application.powerworker.cn/v2/CommunicateRequest/SaveForm",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response?.data?.Tag) {
          message.success("发送成功");
        } else {
          message.error(response.data.Message);
        }
        // console.log(response?.data?.Tag);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setWidth(window.innerWidth);
    });
  }, [width]);

  // console.log(width);

  return (
    <div>
      {document.body.clientWidth > 1000 && (
        <div className="footer">
          <div className="center">
            <h1>请求沟通</h1>
            <div className="input_title">
              <div>主题:</div>
              <div>姓名:</div>
              <div>公司:</div>
            </div>
            <Form
              className="footer_input"
              layout="inline"
              name="control-hooks"
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="noticeObject">
                <Select placeholder="选择主题">
                  <Select.Option value="产品生命周期管理">
                    产品生命周期管理
                  </Select.Option>
                  <Select.Option value="三维可视化">三维可视化</Select.Option>
                  <Select.Option value="合理化建议">合理化建议</Select.Option>
                  <Select.Option value="经验教训管理">
                    经验教训管理
                  </Select.Option>
                  <Select.Option value="系统定制">系统定制</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="CName"
                rules={[
                  {
                    required: true,
                    message: "请输入你的姓名",
                  },
                ]}
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>

              <Form.Item
                name="CompanyName"
                rules={[
                  {
                    required: true,
                    message: "请输入你的公司",
                  },
                ]}
              >
                <Input placeholder="请输入公司" />
              </Form.Item>
              {/* <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  lineHeight: "32px",
                  color: "white",
                  fontWeight: "bolder",
                }}
              >
                <span>邮箱：</span>
                <span style={{ marginLeft: "410px" }}>手机号：</span>
              </p> */}
              {/* <div style={{  }}>
                <div>邮箱：</div>
                <div>手机号：</div>
              </div> */}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "请输入你的邮箱",
                  },
                ]}
              >
                <div className="form-title">邮箱：</div>
                <Input placeholder="请输入你的邮箱" />
              </Form.Item>

              <Form.Item
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: "请输入你的手机号",
                  },
                ]}
              >
                <div className="form-title">手机号：</div>
                <Input placeholder="请输入你的手机号" />
              </Form.Item>
              <div style={{ display: "flex", marginTop: "30px" }}>
                <Form.Item
                  name="ifSubscribed"
                  valuePropName="checked"
                  wrapperCol={{
                    offset: 0,
                    span: 0,
                  }}
                  className="checkbox"
                >
                  <Checkbox className="checkbox">订阅信息</Checkbox>
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 0,
                    span: 9,
                  }}
                  className="submit_box"
                >
                  <Button type="primary" htmlType="submit">
                    发送
                  </Button>
                </Form.Item>
              </div>
            </Form>

            <div className="footer_text footer_input">
              <div className="footer_logo">
                <img src={euci} alt="" className="euci" />
              </div>
              <div className="introduce">
                <h3 className="company">上海熠格信息技术有限公司</h3>
                <span>
                  熠格EUCI
                  Software是专业的制造业信息管理领域的优质供应商，致力于引入、研发、交付面向制造业具有可实施性、创新性的工程软件和数字化管理方案，赋能企业项目管理、产品研发、工程制造等业务领域的过程优化和管理变革。
                  {/* <i className="iconfont icon-gengduo"></i> */}
                </span>
              </div>
              <div className="fast_box">
                <h3 className="fast">快速链接</h3>
                <ul>
                  <li>
                    <a href="https://euci-software.com/news">新闻中心</a>
                  </li>
                  <li>
                    <a href="https://euci-software.com/technology">技术中心</a>
                  </li>
                  <li>
                    <a href="https://euci-software.com/product?contact">
                      CIM Database
                    </a>
                  </li>
                  <li>
                    <a href="https://euci-software.com/product?3dvs">
                      Kisters 3DViewstation
                    </a>
                  </li>
                  <li>
                    <a href="https://euci-software.com/product?analy">
                      Analytics Creator
                    </a>
                  </li>
                </ul>
              </div>
              <div className="new">
                <h3>新媒体频道</h3>
                <img src={code} className="code" alt="" />
                <p className="wx">微信公众号</p>
              </div>
              <div className="partner">
                <h3>合作伙伴</h3>
                <a href=" https://contact-software.com" target="_blank">
                  <img src={contact} alt="" className="contact" />
                </a>
                <a
                  href="https://viewer.kisters.de/en/index.html"
                  target="_blank" rel="noreferrer"
                >
                  <img src={kisters} alt="" className="kisters" />
                </a>
                <a
                  href="https://www.analyticscreator.com"
                  target="_blank" rel="noreferrer"
                >
                  <img src={analy} alt="" className="analytics" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      {document.body.clientWidth < 1000 && (
        <div className="footer">
          <div className="footer_center">
            <h1>请求沟通</h1>
            {/* <div className="input_title">
              <div>主题:</div>
              <div>姓名:</div>
              <div>邮箱:</div>
            </div> */}
            <Form
              className="footer_input"
              layout="inline"
              name="control-hooks"
              form={form}
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item name="noticeObject" className="produceCon">
                <Select placeholder="选择主题">
                  <Select.Option value="产品生命周期管理">
                    产品生命周期管理
                  </Select.Option>
                  <Select.Option value="三维可视化">三维可视化</Select.Option>
                  <Select.Option value="合理化建议">合理化建议</Select.Option>
                  <Select.Option value="经验教训管理">
                    经验教训管理
                  </Select.Option>
                  <Select.Option value="系统定制">系统定制</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="CName"
                rules={[
                  {
                    required: true,
                    message: "请输入你的姓名",
                  },
                ]}
              >
                <Input placeholder="输入姓名" />
              </Form.Item>

              <Form.Item
                name="CompanyName"
                rules={[
                  {
                    required: true,
                    message: "请输入你的公司",
                  },
                ]}
              >
                <Input placeholder="输入公司" />
              </Form.Item>

              {/* <p
                style={{
                  width: "100%",
                  fontSize: "15px",
                  lineHeight: "32px",
                  color: "white",
                  fontWeight: "bolder",
                }}
              >
                <span>邮箱：</span>
                <span style={{ marginLeft: "368px" }}>电话：</span>
              </p> */}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "请输入你的邮箱",
                  },
                ]}
              >
                <Input placeholder="请输入你的邮箱" />
              </Form.Item>

              <Form.Item
                name="Phone"
                rules={[
                  {
                    required: true,
                    message: "请输入你的手机号",
                  },
                ]}
              >
                <Input placeholder="请输入你的手机号" />
              </Form.Item>

              <Form.Item
                name="ifSubscribed"
                valuePropName="checked"
                wrapperCol={{
                  offset: 0,
                  span: 0,
                }}
                className="checkbox"
              >
                <Checkbox className="checkbox">订阅信息</Checkbox>
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 0,
                  span: 9,
                }}
                className="submit_box"
              >
                <Button type="primary" htmlType="submit">
                  发送
                </Button>
              </Form.Item>
            </Form>

            <div className="footer_text footer_input">
              <div className="footer_logo">
                <img src={euci} alt="" className="euci" />

                <span>
                  <span className="company"></span>

                  <span className="company_desc">
                    <p className="company_name"> 上海熠格信息技术有限公司</p>
                    熠格EUCI
                    Software是专业的制造业信息管理领域的优质供应商，致力于引入、研发、交付面向制造业具有可实施性、创新性的工程软件和数字化管理方案，赋能企业项目管理、产品研发、工程制造等业务领域的过程优化和管理变革。
                  </span>

                  {/* <i className="iconfont icon-gengduo"></i> */}
                </span>
              </div>
              <div className="introduce"></div>
              <div className="fast_box">
                <h3 className="fast">快速链接</h3>
                <ul>
                  <li>
                    <a href="http://101.227.63.123:9000/news">新闻中心</a>
                  </li>
                  <li>
                    <a href="http://101.227.63.123:9000/technology">技术中心</a>
                  </li>
                  <li>
                    <a href="http://101.227.63.123:9000/product">
                      {" "}
                      CIM Database
                    </a>
                  </li>
                  <li>
                    <a href="http://101.227.63.123:9000/product">
                      Kisters 3DViewstation
                    </a>
                  </li>
                  <li>
                    <a href="http://101.227.63.123:9000/product?analy">
                      Analytics Creator
                    </a>
                  </li>
                </ul>

                <div className="new">
                  <h3>新媒体频道</h3>
                  <img src={code} className="code" />
                  <p className="wx">微信公众号</p>
                </div>
              </div>

              <div className="partner">
                <h3>合作伙伴</h3>
                <a href=" https://contact-software.com" target="_blank">
                  <img src={contact} alt="" className="contact" />
                </a>
                <a
                  href="https://viewer.kisters.de/en/index.html"
                  target="_blank" rel="noreferrer"
                >
                  <img src={kisters} alt="" className="kisters" />
                </a>
                <a
                  href="https://www.analyticscreator.com"
                  target="_blank" rel="noreferrer"
                >
                  <img src={analy} alt="" className="analytics" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="bottom">
        <p>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            className="a_one" rel="noreferrer"
          >
            沪ICP备16035090号-1
          </a>
          <img src={guo} alt="" />
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202013071"
            target="_blank"
            className="a_two" rel="noreferrer"
          >
            沪公网安备 31011202013071号
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
