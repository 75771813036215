import React from "react";
import { Vs3dText } from "../contanst";

const VsText = () => {
  return (
    <div className="flex">
      <div className="pro_text_box">
        {Vs3dText.map((item, i) => {
          return (
            <div className="pro_text_box_center" key={i}>
              <div className="pro_text1">
                <div className="pro_text_one">
                  <div>
                    <img src={item.pic} alt="" />
                  </div>
                  <div className="pro_text_world">
                    <h1>
                      <span>{item.bold}</span>
                      {item.title}
                    </h1>
                    <p>{item.text}</p>
                    {/* <button>更多信息</button> */}
                  </div>
                </div>
                <div className="pro_text_two">
                  <div className="pro_text_two_world">
                    <h1>
                      <span>{item.bold1}</span>
                      {item.title}
                    </h1>
                    <p>{item.text1}</p>
                    {/* <button>更多信息</button> */}
                  </div>
                  <div>
                    <img src={item.pic1} alt="" />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VsText;
