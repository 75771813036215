import ReactDOM from 'react-dom'
import Router from './router'
import './assets/css/base.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './rem.js'
// import 'lib-flexible'



ReactDOM.render(
        <Router/>,
    document.getElementById('root')
)

