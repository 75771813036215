import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import { getCarousel } from "../utils";
import styles from "@css/carousel.module.css";
import "@css/carousel.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import loading from "@images/loading.png";

const Carousel = () => { 
  const [current, setCurrent] = useState(0);
  const [news, setNews] = useState([]);
  const [winUrlSplit, setWinUrlSplit] = useState('');
  const [load, setLoad] = useState("");
  const sliderRef = useRef(null);

  const settings = {
    // dotsClass: "slick-dots slick-thumb",
    adaptiveHeight: true,
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    afterChange: (current) => {
      setCurrent(current);
    },
  };

  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
    console.log(winUrlSplit);
  }, [window.location])

  useEffect(() => {
    setLoad(false);
    getCarousel()
      .then((res) => {
        setNews(res.Data);
      })
      .finally(() => {
        setLoad(true);
      });
  }, []);
  const handleItemClick = (i) => {
    setCurrent(i);
    sliderRef.current.slickGoTo(i);
    console.log(i);
  };
  return (
    <>
      {load ? (
        <div className="carousel_box">
          <div className="carousel">
            <Slider {...settings} className={styles.slickSlider} ref={sliderRef}>
              {news.map((item, index) => {
                return (
                  <a
                    href={`/detail/${item.LinkNewsId}?type=${winUrlSplit}`}
                    target={"_blank"}
                    key={index}
                    rel="noreferrer"
                  >
                    <img src={item.AdvImageFullUrl} id="img" alt="" />
                  </a>
                );
              })}
            </Slider>
          </div>
          <div className="carousel_text">
            {news.map((item, i) => {
              return (
                <div className="carousel-click" key={i} onClick={() => handleItemClick(i)}>
                  <div className={i === current ? "active2" : undefined}>
                    <p className="carousel_title">{item.AdvTitle}</p>
                    <p className="carousel_text_word">{item.AdvDesc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="loadingCarousel">
          <img src={loading} alt="" />
          <p>加载中...</p>
        </div>
      )}
    </>
  );
};

export default Carousel;
