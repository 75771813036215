import React from "react";
import Header from "@components/Header";
import "@css/company.css";
import engineer from "@images/engineer.png";
import eye from "@images/eye.png";
import lamp from "@images/lamp.png";
import Customer from "../components/Customer";
import Footer from "../components/Footer";
import Number from "../components/Number";
import Time from "../components/Time";
import icon from "@images/company/icon.png";
import euci from '@images/euci.svg';
import field from "@images/company/产品服务组合.svg";
import python from "@images/python.png";
import net from "@images/net.png";
import reactlogo from "@images/reactlogo.png";
import yuan from "@images/yuan.png";
import DocumentTitle from "react-document-title";

const Company = () => {
  return (
    <DocumentTitle title="制造业PLM产品生命周期管理和信息化应用专业解决方案供应商！">
      <div>
        <Header />

        <div className="banner_box">
          <div className="black_overlay">
            <div className="banner_container">
              <h1>About Company</h1>
              <h2>
                熠格EUCI
                Software是专业的制造业信息管理领域的优质供应商，致力于引入、研发、交付面向制造业具有可实施性、创新性的工程软件和数字化管理方案，赋能企业项目管理、产品研发、工程制造等业务领域的过程优化和管理变革。
              </h2>
            </div>
          </div>
        </div>

        {/* 使命愿景 */}
        <div className="flex">
          <div className="mission">
            <div className="mission_top">
              <img src={engineer} alt="" />
            </div>
            <div className="mission_img">
              <img src={eye} alt="" />
            </div>
            <div className="mission_text">
              <h2>使命愿景</h2>
              <p>
                致力于<span>引入</span>、<span>研发</span>、<span>交付</span>
                面向制造业<span>可实施性</span>、<span>创新性</span>的
                <span>工程软件</span>和<span>数字化管理方案</span>。赋能企业
                <span>项目管理</span>、<span>产品研发</span>、
                <span>工程制造</span>等核心业务领域的<span>管理改善</span>
              </p>
              <ul>
                <li>
                  <i className="iconfont icon-triangle-right"></i>
                  成为系统级软件产品研发的<span>科技型公司</span>
                </li>
                <li>
                  <i className="iconfont icon-triangle-right"></i>
                  成为著名商业平台厂商的<span>紧密合作伙伴</span>
                </li>
                <li>
                  <i className="iconfont icon-triangle-right"></i>
                  成为成长型制造企业的<span>长期服务伙伴</span>
                </li>
                <li>
                  <i className="iconfont icon-triangle-right"></i>
                  成为工程信息化服务领域的<span>影响力品牌</span>
                </li>
              </ul>
            </div>
            {document.body.clientWidth > 1000 && (
              <>
                <div className="mission_text ">
                  <h2>价值观</h2>
                  <p>
                    创造<span>自由轻松</span>的工作氛围、鼓励
                    <span>独立思考</span>
                    、崇尚<span>技术</span>和<span>实践</span>、
                    <span>尊重客户</span>、<span>真诚服务</span>
                    ,塑造和展现以下的内在品质:
                  </p>
                  <p className="misstion_p">
                    <span>
                      <i className="iconfont icon-triangle-right"></i>独特性
                    </span>
                  </p>
                  <p className="misstion_p">
                    <span>
                      <i className="iconfont icon-triangle-right"></i>创造性
                    </span>
                  </p>
                  <p className="misstion_p">
                    <span>
                      <i className="iconfont icon-triangle-right"></i>整合性
                    </span>
                  </p>
                </div>
                <div className="mission_img ">
                  <img src={lamp} alt="" />
                </div>
              </>
            )}
            {document.body.clientWidth < 1000 && (
              <>
                <div className="mission_img ">
                  <img src={lamp} alt="" />
                </div>
                <div className="mission_text ">
                  <h2>价值观</h2>
                  <p>
                    创造<span>自由轻松</span>的工作氛围、鼓励
                    <span>独立思考</span>
                    、崇尚<span>技术</span>和<span>实践</span>、
                    <span>尊重客户</span>、<span>真诚服务</span>
                    ,塑造和展现以下的内在品质: 
                  </p>
                  <p className="misstion_p">
                    <span>
                      <i className="iconfont icon-triangle-right"></i>独特性
                    </span>
                  </p>
                  <p className="misstion_p">
                    <span>
                      <i className="iconfont icon-triangle-right"></i>创造性
                    </span>
                  </p>
                  <p className="misstion_p">
                    <span>
                      <i className="iconfont icon-triangle-right"></i>整合性
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        {/* long_four */}
        <Number />

        {/* 时间轴 */}
        <Time />

        {/* 业务领域 */}
        <div className="flex">
          <h1 className="field_title">产品服务组合</h1>
          <div className="field_text_box">
            <div className="field_text field_one">
              {/* <img src={icon} alt="" /> */}
              <div>
                致力于引入、研发和交付面向制造业产品数字化，最具实践性、创新性的应用软件和管理系统。
              </div>
            </div>
            <img src={field} className="field_big" alt="" />
          </div>
        </div>

        {/* 服务能力 */}
        {/* <div className="service_big"></div> */}
        <div className="flex">
          <h1 className="service_title">服务能力</h1>
          <div className="service_bigcontent">
            <div className="service_one">
              <div className="service_left">
                <h1>全栈软件技能的开发团队</h1>
                <p>
                  我们拥有专业的软件开发团队,从前端流行框架React到后端高级语言Python,.net,以及移动APP开发的全栈软件技能的储备,满足企业IT技术实现的各种挑战,我们已经成功为企业客户交付20多套定制化完整系统
                </p>
                {/* <button>技术中心了解更多</button> */}
              </div>
              <div className="servicr_right">
                <img src={python} className="python" alt="" />
                <img src={net} className="net" alt="" />
                <img src={reactlogo} className="reactlogo" alt="" />
                <span>React</span>
              </div>
            </div>

            <div className="service_one">
              <div className="servicr_right">
                <img src={yuan} className="yuan" alt="" />
              </div>
              <div className="service_left">
                <h1 className="service_left_title">实战经验丰富的顾问团队</h1>
                <p>
                  从帮助企业分析业务问题和提供解决方案的咨询顾问，到将系统完整按需交付给客户的实施顾问,我们拥有完整的构建方案到部署落地的独特方法论和实践能力
                </p>
                {/* <button className="service_left_btn">技术中心了解更多</button> */}
              </div>
            </div>
          </div>
        </div>

        {/* 客户 */}
        <div className="flex">
          <Customer />
        </div>

        {/* 底部 */}
        <Footer />
      </div>
    </DocumentTitle>
  );
};

export default Company;
