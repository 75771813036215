import React, { useEffect, useState } from "react";
import "@css/customer.css";
import bosal from "@images/company/customer-img/1.png";
import easun from "@images/company/customer-img/2.png";
import fft from "@images/company/customer-img/3.png";
import gaobologo from "@images/company/customer-img/4.png";
import gpluse from "@images/company/customer-img/5.png";
import intron from "@images/company/customer-img/7.png";
import kostal from "@images/company/customer-img/8.png";
import mold from "@images/company/customer-img/9.png";
import penn from "@images/company/customer-img/10.png";
import sdh from "@images/company/customer-img/11.png";
import sitech from "@images/company/customer-img/12.png";
import ute from "@images/company/customer-img/13.png";
import yfpo from "@images/company/customer-img/14.png";
import zuiko from "@images/company/customer-img/15.png";
import shobo from '@images/company/customer-img/16.png';
import power from '@images/company/customer-img/17.png';
import nswi from '@images/company/customer-img/18.png'
import APD from "@images/company/customer-img/19.png";
import BBS from "@images/company/customer-img/20.png";
import Kostal from "@images/company/customer-img/21.png";
import newasia from "@images/company/customer-img/22.png"; 
import zhiyanhui from '@images/company/customer-img/23.png';
import sodecia from '@images/company/customer-img/24.png';
import wuyang from '@images/company/customer-img/25.png'
import amphenol from "@images/company/customer-img/26.jpg"; 
import lydall from '@images/company/customer-img/27.jpg';
import hp from '@images/company/customer-img/28.jpg';
import brosesitech from '@images/company/customer-img/29.jpg'
import tianan from '@images/company/customer-img/30.png'
import techology from '@images/company/customer-img/31.jpg'

import right from '@images/company/customer-img/right.png';
import top from '@images/company/customer-img/top.png'
const Customer = () => {
  const customImg = [
    bosal, easun, fft, gaobologo, gpluse, brosesitech, intron, kostal, mold,
    penn, sdh, sitech, ute, yfpo, zuiko, shobo, power, nswi, APD, BBS, Kostal,
    newasia, zhiyanhui, sodecia, wuyang, amphenol, lydall, hp, tianan, techology
  ];
  return (
    <div className="client">
      <div className="client_title">
        <h1>客户</h1>
      </div>
      <div className="image-row"> 
        {customImg.map((image, index) => (
          <div
            style={{
              margin: '10px'
            }}
            key={index}
          >
            <img className={index === 11 || index === 5 ? 'image-png' : "image-style"} src={image} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
  // const imagesPerRow = 9;
  // const rowsPerPage = 3;

  // const [showHiddenImages, setShowHiddenImages] = useState(false);

  // const handleToggleHiddenImages = () => {
  //   setShowHiddenImages(!showHiddenImages);
  // };

  // const renderImages = () => {
  //   const imagesToDisplay = showHiddenImages ? customImg : customImg.slice(0, imagesPerRow * rowsPerPage);
  //   return imagesToDisplay.map((image, index) => (
  //     <div
  //       key={index}
  //       style={{
  //         margin: '10px',
  //         display: 'block',
  //       }}
  //     >
  //       <img
  //         className="image-style"
  //         src={image}
  //         alt=""
  //       />
  //     </div>
  //   ));
  // };

  // const renderButton = () => {
  //   const hiddenImagesCount = customImg.length - (imagesPerRow * rowsPerPage);

  //   if (hiddenImagesCount > 0) {
  //     const buttonImage = showHiddenImages ? top : right;

  //     return (
  //       <div style={{ margin: '10px' }}>
  //         <img
  //           className="image-jiantou"
  //           src={buttonImage}
  //           alt=""
  //           onClick={handleToggleHiddenImages}
  //         />
  //       </div>
  //     );
  //   }

  //   return null;
  // };

  // return (
  //   <div className="client">
  //     <div className="client_title">
  //       <h1>客户</h1>
  //     </div>
  //     <div className="image-row">
  //       {renderImages()}
  //       {renderButton()}
  //     </div>
  //   </div>
  // );
};

export default Customer;
