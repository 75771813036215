import React from "react";
import "@css/number.css";
import left from "@images/left.svg";
import right from "@images/right.svg";
import one from "@images/one.svg";
import two from "@images/two.svg";
import three from "@images/three.svg";
import four from "@images/four.svg";

const Number = () => {
  return (
    <div className="com_long">
      <div className="long_con">
        <div>
          <img src={left} className="left_img" />
          <img src={one} className="middle_img" />
          <img src={right} className="right_img" />
          {/* <span>3</span> */}
          <p>国家高新科技企业认定 3年</p>
        </div>
        <div>
          <img src={left} className="left_img" />
          <img src={two} className="middle_img" />
          <img src={right} className="right_img" />
          {/* <span id="num">15</span> */}
          <p>软件著作权 15+</p>
        </div>
        <div>
          <img src={left} className="left_img" />
          <img src={three} className="middle_img" />
          <img src={right} className="right_img" />
          {/* <span id="num">25</span> */}
          <p>定制系统 25+</p>
        </div>
        <div>
          <img src={left} className="left_img" />
          <img src={four} className="middle_img" />
          <img src={right} className="right_img" />
          {/* <span id="num">20</span> */}
          <p>服务客户 20+</p>
        </div>
      </div>
    </div>
  );
};

export default Number;
