import http from '../utils/http'




// 新闻
function getContact (pageIndex) {
    return http("get", `/v2/News/GetPageList?NewsStatus=3&&NewsType=440132916874317824&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getAnaly (pageIndex) {
    return http("get", `/v2/News/GetPageList?NewsStatus=3&&NewsType=606807237008035840&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getVs (pageIndex) {
    return http("get", `/v2/News/GetPageList?NewsStatus=3&&NewsType=440132989125398528&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getEuci (pageIndex) {
    return http("get", `/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=276497041524264960,440133545298497536&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getAllnews (pageIndex) {
    return http('get', `/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440132916874317824,446657311344168960,606807237008035840,440132989125398528,276497041524264960&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
// 头部
function getNewsCar () {
    return http('get', '/v2/WebLabelContent/GetList?LabelShortName=NewsTop&CompanyId=276350460217004032')
}

// 首页
function getHomeTech () {
    return http('get', '/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440133350192058368,440133545298497536,606509804877254656,446657311344168960,440134446302105600,440133545298497536,440134297819549696&&PageSize=5&&PageIndex=1&&BaseIsDelete=0')
}
function getHomeNews () {
    return http('get', '/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440132916874317824,440133545298497536,606807237008035840,440132989125398528,276497041524264960&&PageSize=6&&PageIndex=1&&BaseIsDelete=0')
}
function getProductContact () {
    return http('get', '/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440133350192058368,440132916874317824&&PageSize=6&&PageIndex=1&&BaseIsDelete=0')
}
function getProduct3Dvs () {
    return http('get', '/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440132989125398528,440134446302105600&&PageSize=6&&PageIndex=1&&BaseIsDelete=0')
}
function getProductAnaly () {
    return http('get', '/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=606509804877254656,606807237008035840&&PageSize=6&&PageIndex=1&&BaseIsDelete=0')
}
function getPowerWorkNews () {
    return http('get', '/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440133545298497536&&PageSize=6&&PageIndex=1&&BaseIsDelete=0')
}
function getFooter (value) {
    return http('post', '/v2/CommunicateRequest/SaveForm')
}

// 技术中心
// 头部
function getTechCar () {
    return http('get', '/v2/WebLabelContent/GetList?LabelShortName=TechnicalCenterTopNews&CompanyId=276350460217004032')
}
function getCIM (pageIndex) {
    return http('get', `/v2/News/GetPageList?NewsStatus=3&&NewsType=440133350192058368&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getVS (pageIndex) {
    return http('get', `/v2/News/GetPageList?NewsStatus=3&&NewsType=440134446302105600&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getAnalyTics (pageIndex) {
    return http('get', `/v2/News/GetPageList?NewsStatus=3&&NewsType=606509804877254656&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getZhizong (pageIndex) {
    return http('get', `/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440134297819549696,440133545298497536,446657311344168960&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}
function getTechAll (pageIndex) {
    return http('get', `/v2/News/GetPageList?NewsStatus=3&&NewsTypeStr=440134297819549696,606509804877254656,440133545298497536,440133350192058368,440134446302105600&&PageSize=8&&PageIndex=${pageIndex}&&BaseIsDelete=0&&CompanyId=276350460217004032`)
}

// 轮播
function getCarousel () {
    return http('get', '/v2/WebAdvertisementContent/GetList?AdvShortName=IndexTopAdv&&CompanyId=276350460217004032')
}

// 详情
function getNewsDetail (id) {
    return http('get', `/v2/News/GetForm?Id=${id}`)
}

// 产品
// contact
function getProCar () {
    return http('get', '/v2/WebAdvertisementContent/GetList?AdvShortName=ProductContact&&CompanyId=276350460217004032')
}

// analy
function getProAnaly () {
    return http('get', '/v2/WebAdvertisementContent/GetList?AdvShortName=ProductAnalyticsCreator&&CompanyId=276350460217004032')
}

// 3dvs
function getProVs () {
    return http('get', '/v2/WebAdvertisementContent/GetList?AdvShortName=Product3DViewstation&&CompanyId=276350460217004032')
}

// powerwork
function getProPowerWork () {
    return http('get', '/v2/WebAdvertisementContent/GetList?AdvShortName=ProductPowerWork&&CompanyId=276350460217004032')
}
export {
    getContact,
    getAnaly,
    getVs,
    getEuci,
    getHomeTech,
    getHomeNews,
    getProductAnaly,
    getProductContact,
    getProduct3Dvs,
    getPowerWorkNews,
    getAllnews,
    getNewsCar,
    getTechCar,
    getCIM,
    getVS,
    getAnalyTics,
    getZhizong,
    getTechAll,
    getNewsDetail,
    getCarousel,
    getProCar,
    getProVs,
    getProAnaly,
    getProPowerWork,
    getFooter
}

