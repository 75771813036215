import React, { useEffect, useState } from "react";
import Header from "@components/Header";
import "@css/home.css";
import ThreeText from "@components/ThreeText";
import Footer from "@components/Footer";
import Text from "@components/Text";
import Customer from "@components/Customer";
import Carousel from "@components/Carousel";

import DocumentTitle from "react-document-title";
import TechnicalCenter from "@components/Technical_center";

const Home = () => {
 
  

  return (
    <DocumentTitle title="制造业PLM产品生命周期管理和信息化应用专业解决方案供应商！">
      <div>
        {/* 头部 */}
        <Header />

        {/* 轮播 */}
        <div className="flex">
          <Carousel />
        </div>

        {/* 长块 */}
        <Text />

        {/* 新闻中心 */}
        <div className="flex">
          <h1 className="title">新闻</h1>
          <ThreeText home={0} />
        </div>

        {/* 技术中心 */}
        <TechnicalCenter/>
        

        {/* 客户 */}
        <div className="flex">
          <Customer />
        </div>

        {/* 底部 */}
        <Footer />
      </div>
    </DocumentTitle>
  );
};

export default Home;
