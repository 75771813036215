import React from "react";
import Header from "@components/Header";
import Newstech from "@components/Newstech";
import Footer from "@components/Footer";
import Searchinput from "@components/Searchinput";
import DocumentTitle from "react-document-title";

const Tech = () => {
  return (
    <DocumentTitle title="制造业PLM产品生命周期管理和信息化应用专业解决方案供应商！">
      <div>
          
        <Header />

        <div className="flex">
          <Newstech datas={1} />
        </div>

        <div className="flex">
          <Searchinput />
        </div>

        <Footer />
      </div>
    </DocumentTitle>
  );
};

export default Tech;
