import React from "react";
import Header from "@components/Header";
import Newstech from "@components/Newstech";
import Footer from "@components/Footer";
import Searchfour from "@components/SearchFour";
import DocumentTitle from "react-document-title";

const News = () => {
  return (
    <DocumentTitle title="制造业PLM产品生命周期管理和信息化应用专业解决方案供应商！">
      <div>
        <Header />

        <div className="flex">
          <Newstech data={1} />
        </div>

        <div className="flex">
          <Searchfour />
        </div>

        <Footer />
      </div>
    </DocumentTitle>
  );
};

export default News;
