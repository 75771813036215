import Home from "../pages/Home";
import Tech from "../pages/Tech";
import News from "../pages/News";
import Product from "../pages/Product";
import Relation from "../pages/Relation";
import Company from "../pages/Company";
import ProductLightweight from "../pages/Product_lightweight";
import Detail from "../components/detail/Detail";
import Viewstation from "../pages/Viewstation";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

const BaseRouter = () => (
  <Router basename="/">
    <Routes>
      <Route path="*" element={<Navigate to="/home" />}></Route>
      <Route path="/home" element={<Home />}></Route>
      <Route path="/technology" element={<Tech />}></Route>
      <Route path="/news" element={<News />}></Route>
      <Route path="/product" element={<Product />}></Route>
      <Route path="/detail/:id" element={<Detail />}></Route>

      <Route path="/product" element={<ProductLightweight />} />
      <Route path="/relation" element={<Relation />}></Route>
      <Route path="/company" element={<Company />}></Route>
      <Route path="/vs" element={<Viewstation />}></Route>
    </Routes>
  </Router>
);

export default BaseRouter;
