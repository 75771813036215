import React, { useState, useCallback } from "react";
import "@css/product/pro_customer.css";
import { List, Car, CarTotal } from "../contanst/pro_customer";
import OnlyCar from "@components/customer/OnlyCar";
import High from "@components/customer/High";
import Traffic from "@components/customer/Traffic";
import Mechanics from "@components/customer/Mechanics";

const ProductCustomer = () => {
  const [current, setCurrent] = useState(0);
  const [more, setMore] = useState(0);
  const onClickList = (i) => {
    setCurrent(i);
  };
  const onClickCar = (index) => {
    // console.log(index);
    setMore(index);
  };
  const show = useCallback((cur) => {
    return current === cur ? "pro_customerLi" : null;
  },[current]);
  const Moretotal = () => {
    return (
      <div className="pro_customer_Exhibition">
        {CarTotal.map((item, index) => {
          return (
            <div key={index}>
              <img
                src={item.pic}
                className="customer_img"
                onClick={() => onClickCar(index)}
                alt=""
              />
            </div>
          );
        })}
      </div>
    );
  };
  const Morecar = () => {
    return (
      <div className="pro_customer_Exhibition">
        {Car.map((item, index) => {
          return (
            <div key={index} onClick={() => onClickCar(index)}>
              <img src={item.pic} className="customer_img" alt="" />
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div className="pro_customer">
      <ul>
        {List.map((item, i) => {
          return (
            <li key={i} onClick={() => onClickList(i)} className={show(i)}>
              {item.li}
            </li>
          );
        })}
      </ul>
      {
        // 汽车、交通切换
        (() => {
          if (current === 0) {
            return (
              <div>
                {
                  // 全部收起
                  (() => {
                    if (more === 29) {
                      return (
                        <div>
                          <Moretotal />
                        </div>
                      );
                    } else if (more === 58) {
                      return (
                        <div>
                          <Morecar />
                        </div>
                      );
                    } else if (more > 29 && more < 58) {
                      return (
                        <div>
                          <Moretotal />
                        </div>
                      );
                    } else {
                      return (
                        <div>
                          <Morecar />
                        </div>
                      );
                    }
                  })()
                }
              </div>
            );
          } else if (current === 1) {
            return (
              <div>
                <OnlyCar />
              </div>
            );
          } else if (current === 2) {
            return (
              <div>
                <High />
              </div>
            );
          } else if (current === 3) {
            return (
              <div>
                <Traffic />
              </div>
            );
          } else if (current === 4) {
            return (
              <div>
                <Mechanics />
              </div>
            );
          }
        })()
      }
    </div>
  );
};

export default ProductCustomer;
