import React from 'react';
import { traffic } from '../../contanst/pro_customer';
import '../../assets/css/product/customer.css'

const Traffic = () => {
    return (
        <div className='pro_customer_Exhibition1'>
            {
                traffic.map((item, index) => {
                    return (
                        <div key={index}  >
                            <img src={item.pic} className='customer_img' />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default Traffic;
