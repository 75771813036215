import React, { useState, useEffect } from "react";
import "../assets/css/time.css";
import { Timeline } from "antd";
import { TimeText } from "../contanst";
import { TimeImg } from "../contanst";
import { CheckCircleOutlined } from "@ant-design/icons";

const Time = () => {
  const [current, setCurrent] = useState(1);

  const clickEvent = (id) => {
    setCurrent(id);
  };

  useEffect(() => {
    const timeCont = setInterval(() => {
      if (current > 3) {
        setCurrent(1);
      } else {
        setCurrent(current + 1);
      }
    }, 3000);
    return () => clearInterval(timeCont);
  }, [current]);

  return (
    <div className="flex">
      <div className="time_big">
        <div className="time_left">
          <Timeline>
            {TimeText.map((item, i) => {
              return (
                <Timeline.Item
                  key={item.id}
                  dot={
                    item.id === current ? (
                      <CheckCircleOutlined className="CheckCircleOutlined" />
                    ) : null
                  }
                >
                  <div
                    onClick={() => clickEvent(item.id)}
                    className="time_text"
                  >
                    <div className="time_data">{item.time}</div>
                    <div className="time_world">{item.text}</div>
                    <div>
                      <img src={item.img} className="time_left_img" alt="" />
                    </div>
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        </div>
        <div className="time_right">
          {TimeImg.map((item) => {
            return (
              <div key={item.id}>
                {item.id === current && !item.shows ? (
                  <div>
                    <img src={item.img} className="time_right_img" alt="" />
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Time;
