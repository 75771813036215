import React, { useEffect, useState } from "react";
import { getHomeTech } from "../utils";
import { Link } from "react-router-dom";
import '@css/technology_center.css'
import loading from "@images/loading.png";

const TechnicalCenter = () => {
  const [load, setLoad] = useState("");
  const onclickRoute = () => {
    localStorage.setItem("route", "home");
  };
  const [technology, setTechnology] = useState([]);
  const [winUrlSplit, setWinUrlSplit] = useState('');

  useEffect(() => {
    const winUrl = window.location.href;
    const winUrlSplit = winUrl.split('/')[3];
    setWinUrlSplit(winUrlSplit)
  }, [window.location])

  useEffect(() => {
    setLoad(false);
    getHomeTech()
      .then((res) => {
        if (!res.BaseCreatorDepartmentName) {
          setTechnology(res.Data);
        }
      })
      .finally(() => {
        setLoad(true);
      });
  }, []);
  return (
    <div className="flex">
      <h1 className="tech_title">技术中心</h1>
      {load ? (
        <div className="tech">
          {technology.map((item, i) => {
            return (
              <div key={i}
              // onClick={() => onclickRoute()}
              >
                <Link to={`/detail/${item.Id}?type=${winUrlSplit}`} target="_blank" className="tech-link-container">
                  <img src={item.FullThumbImage} alt="" />
                </Link>

                <div>
                  <h1>{item.NewsTitle}</h1>
                  <p>
                    {/* {item.NewsDesc} */}
                    <span>
                      {/* <i className="iconfont icon-gengduo"></i> */}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="homeLoading">
          <img src={loading} alt="" />
          <p>加载中...</p>
        </div>
      )}
    </div>
  );
}

export default TechnicalCenter;
